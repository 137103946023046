import React from "react";
import TotalRevenueCard from "../components/AdminDashboardComponents/TotalRevenueCard";
import Status_btn from "../components/Transaction/Status_btn";
import { useEffect } from "react";
import ReactPaginate from "react-paginate";
import { useState } from "react";
import TotalRevenueCardDemoData from "../Demodata/TotalRevenueCardData";
import TransactionDemoData from "../Demodata/TransactionDemoData";
const Transaction = () => {
  //Transactions Pagination
  const CardsPerPage = 4;
  const [currentPage, setCurrentPage] = useState(0);
  const [searchText, setSearchText] = useState("");
  const startIndex = currentPage * CardsPerPage;
  const endIndex = startIndex + CardsPerPage;
  const handlePageClick = (selectedPage) => {
    setCurrentPage(selectedPage.selected);
  };
  const [filteredCard, setFilteredCard] = useState(TransactionDemoData);

  const currentCard = filteredCard.slice(startIndex, endIndex);
  const handleOnSearch = (e) => {
    setSearchText(e.target.value);
  };

  useEffect(() => {
    // Filter stations based on the address when searchText changes
    const filtered = TransactionDemoData.filter((e) => {
      return (
        e.InvoiceId.includes(searchText) ||
        e.RecipientName.toLowerCase().includes(searchText.toLowerCase()) ||
        e.Date.toString().toLowerCase().includes(searchText.toLowerCase() ) ||
        e.Status.toLowerCase().includes(searchText.toLowerCase()) 
      );
    });
    setFilteredCard(filtered);
    setCurrentPage(0); // Reset to the first page when search text changes
  }, [searchText]);
  return (
    <>
      <div className="bg-[#EBF3F9] w-[100%] h-[98%] ">
        <div className="curr-path flex flex-col p-5">
          <h3 className="font-semibold text-xl my-1">Transaction</h3>
          <ul className="flex items-center gap-x-1">
            <li className="text-[#454F5B] text-sm font-normal">Dashboard</li>
            <img
              className="h-3"
              src="/arrow_forward_ios.png"
              alt="/arrow-forward"
            />
            <li className="text-[#212B36] font-medium">Transaction</li>
          </ul>
        </div>
        <div className=" bg-white rounded-lg w-[97%]  ml-5 mb-8">
          <div className="Top">
            <TotalRevenueCard
              propsData={TotalRevenueCardDemoData}
              DropDownPostion={"justify-start pt-3 pl-5 mb-5"}
              backgroundColor={"#098FF9"}
            />
          </div>
          <div className="Bottom">
            <div className="flex mt-10  pr-10 pl-10 justify-between">
              <div
                className=""
                style={{
                  fontWeight: "600",
                  fontSize: "24px",
                }}
              >
                Transaction History
              </div>
              <div className=" h-[100%] flex">
                <div
                  id="search-Transaction"
                  className="bg-[#DFE3E8] flex justify-center items-center p-1 rounded-lg"
                >
                  <span className="material-symbols-outlined">search</span>
                  <input
                    type="search"
                    placeholder="Search for Transactions"
                    className=" ml-3 bg-[#DFE3E8] customInput"
                    value={searchText}
                    onChange={handleOnSearch}
                  />
                </div>
              </div>
            </div>
            <div className=" p-10">
              <table className="w-[100%] custom-table rounded-lg" >
                <thead>
                  <tr>
                    <th
                      className="heading_row"
                      style={{
                        paddingLeft: "15px",
                        paddingTop: "15px",
                        paddingTop: "15px",
                      }}
                    >
                      {" "}
                      S NO.
                    </th>
                    <th
                      className="heading_row"
                      style={{
                        paddingLeft: "15px",
                        paddingTop: "15px",
                        paddingTop: "15px",
                      }}
                    >
                      {" "}
                      Invoice ID
                    </th>
                    <th
                      className="heading_row"
                      style={{
                        paddingLeft: "15px",
                        paddingTop: "15px",
                        paddingTop: "15px",
                      }}
                    >
                      {" "}
                      Date
                    </th>
                    <th
                      className="heading_row"
                      style={{
                        paddingLeft: "15px",
                        paddingTop: "15px",
                        paddingTop: "15px",
                      }}
                    >
                      {" "}
                      Recipient Name
                    </th>
                    <th
                      className="heading_row"
                      style={{
                        paddingLeft: "15px",
                        paddingTop: "15px",
                        paddingTop: "15px",
                      }}
                    >
                      {" "}
                      Amount
                    </th>
                    <th
                      className="heading_row"
                      style={{
                        paddingLeft: "15px",
                        paddingTop: "15px",
                        paddingTop: "15px",
                      }}
                    >
                      {" "}
                      Payment Type
                    </th>
                    <th
                      className="heading_row"
                      style={{
                        paddingLeft: "15px",
                        paddingTop: "15px",
                        paddingTop: "15px",
                      }}
                    >
                      {" "}
                      Status
                    </th>
                    <th
                      className="heading_row"
                      style={{
                        paddingLeft: "15px",
                        paddingTop: "15px",
                        paddingTop: "15px",
                      }}
                    >
                      {" "}
                      Location
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {currentCard.map((e) => {
                    return (
                      <tr key={e.SerialNumber}>
                        <td className="item">{e.SerialNumber}</td>
                        <td className="item">{e.InvoiceId}</td>
                        <td className="item">{e.Date}</td>
                        <td className="item">{e.RecipientName}</td>
                        <td className="item">{e.Amount}</td>
                        <td className="item">{e.PaymentType}</td>
                        <td className="item ">
                          <Status_btn
                            color={`${
                              e.Status === "Completed"
                                ? "rgb(49 147 106)"
                                : e.Status === "Pending"
                                ? "#EF9E00"
                                : "#212B36"
                            }`}
                            backgroundColor={`${
                              e.Status === "Completed"
                                ? "#99EFCB"
                                : e.Status === "Pending"
                                ? "rgb(255 237 202)"
                                : "#F84F56"
                            }`}
                            status={e.Status}
                          />
                        </td>
                        <td className="item">{e.Location}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              <div className="PaginationRow3">
                <ReactPaginate
                  breakLabel="..."
                  nextLabel=">"
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={5}
                  pageCount={Math.ceil(filteredCard.length / CardsPerPage)}
                  previousLabel="<"
                  containerClassName="pagination flex justify-center gap-4"
                  pageClassName="w-6 h-6 flex justify-center items-center"
                  pageLinkClassName="text-sm font-semibold "
                  activeClassName="bg-[#0078D7] rounded text-[#FFFFFF]"
                  previousLinkClassName="text-[#0078D7] font-bold"
                  nextLinkClassName="text-[#0078D7] font-bold"
                />
              </div>
              <div
                className="grid  rounded-xl"
                style={{
                  gridTemplateRows: "repeat(5)",
                  gridTemplateColumns: "1fr 1fr 1fr 1.5fr 1fr 1fr 1fr 1fr",
                  border: "2px solid #CBD5E1",
                }}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Transaction;
