import React from 'react';
import ClipLoader from "react-spinners/ClipLoader";

const Loader = ({ loading }) => {
  return (
    <div className={` fixed w-full h-screen inset-0 flex justify-center items-center ${loading ? '' : 'hidden'}`} style={{zIndex:"9999"}}>
      <ClipLoader
        loading={loading}
        size={70}
        aria-label="Loading Spinner"
        data-testid="loader"
    
      />
    </div>
  );
};

export default Loader;
