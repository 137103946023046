import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
ChartJS.register(ArcElement, Tooltip, Legend);

function DoughnutChart({totalChargingChargers,totalUnderMaintenanceChargers,totalIdleChargers}) {
    // console.log(typeof(totalChargingChargers));
    const data = {
        labels: ['Charging', 'Idle', 'Under maintenance'],
        datasets: [{
            label: 'Poll',
            data: [totalChargingChargers, totalIdleChargers, totalUnderMaintenanceChargers],
            backgroundColor: ['#00D77D', ' #EF9E00', ' #E40000'],
        }]
    }
    const option = {
        plugins: {
            legend: {
                position: 'right'
            }
        },
        responsive: true,
        maintainAspectRatio: true,
        cutout: 50,
    }
    const textcenter = {
        id: "textcenter",
        beforeDatasetsDraw(chart,args,plusginOptions){
          const{ctx,data} = chart;
          ctx.save();
          ctx.font = 'bolder 40px Roboto';
          ctx.fillStyle = 'black';
          ctx.textAlign = 'center';
          ctx.textBaseline = 'middle';
          ctx.fillText(totalChargingChargers+totalIdleChargers+totalUnderMaintenanceChargers,chart.getDatasetMeta(0).data[0].x,chart.getDatasetMeta(0).data[0].y)
    
        }
      }
    return (
        <div style={{ height: "300px",
            width: "300px", marginTop:"-50px", marginLeft:'20px'}}>
            <Doughnut data={data} options={option} width={300} height={300} plugins={[textcenter]}>
            </Doughnut>
        </div>
    )
}
export default DoughnutChart;