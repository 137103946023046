const TransactionDemoData = [
  {
    SerialNumber: "1",
    InvoiceId: "21451458721",
    Date: "Jan 21 2021",
    RecipientName: "Bhanu",
    Amount: "357",
    PaymentType: "Upi",
    Status: "Completed",
    Location: "Thane Mumbai",
  },
  {
    SerialNumber: "2",
    InvoiceId: "21451458722",
    Date: "Feb 15 2021",
    RecipientName: "Sara",
    Amount: "462",
    PaymentType: "Credit Card",
    Status: "Pending",
    Location: "New York",
  },
  {
    SerialNumber: "3",
    InvoiceId: "21451458723",
    Date: "Mar 10 2021",
    RecipientName: "Tom",
    Amount: "279",
    PaymentType: "PayPal",
    Status: "Completed",
    Location: "Los Angeles",
  },
  {
    SerialNumber: "4",
    InvoiceId: "21451458724",
    Date: "Apr 05 2021",
    RecipientName: "Alice",
    Amount: "598",
    PaymentType: "Bank Transfer",
    Status: "Cancelled",
    Location: "London",
  },
  {
    SerialNumber: "5",
    InvoiceId: "21451458725",
    Date: "May 20 2021",
    RecipientName: "Jake",
    Amount: "185",
    PaymentType: "Cash",
    Status: "Completed",
    Location: "Paris",
  },
  {
    SerialNumber: "6",
    InvoiceId: "21451458726",
    Date: "Jun 15 2021",
    RecipientName: "Sophie",
    Amount: "721",
    PaymentType: "Debit Card",
    Status: "Pending",
    Location: "Berlin",
  },
  {
    SerialNumber: "7",
    InvoiceId: "21451458727",
    Date: "Jul 10 2021",
    RecipientName: "Ryan",
    Amount: "394",
    PaymentType: "Cheque",
    Status: "Completed",
    Location: "Tokyo",
  },
  {
    SerialNumber: "8",
    InvoiceId: "21451458728",
    Date: "Aug 05 2021",
    RecipientName: "Lily",
    Amount: "636",
    PaymentType: "Crypto",
    Status: "Pending",
    Location: "Seoul",
  },
  {
    SerialNumber: "9",
    InvoiceId: "21451458729",
    Date: "Sep 20 2021",
    RecipientName: "Ben",
    Amount: "529",
    PaymentType: "Google Pay",
    Status: "Completed",
    Location: "Sydney",
  },
  {
    SerialNumber: "10",
    InvoiceId: "21451458730",
    Date: "Oct 15 2021",
    RecipientName: "Hannah",
    Amount: "294",
    PaymentType: "Apple Pay",
    Status: "Cancelled",
    Location: "Singapore",
  },
  {
    SerialNumber: "11",
    InvoiceId: "21451458731",
    Date: "Nov 10 2021",
    RecipientName: "Max",
    Amount: "843",
    PaymentType: "Stripe",
    Status: "Completed",
    Location: "Toronto",
  },
  {
    SerialNumber: "12",
    InvoiceId: "21451458732",
    Date: "Dec 05 2021",
    RecipientName: "Emma",
    Amount: "427",
    PaymentType: "Venmo",
    Status: "Pending",
    Location: "Hong Kong",
  },
];
export default TransactionDemoData;
