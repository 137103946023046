import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
ChartJS.register(ArcElement, Tooltip, Legend);

function DoughnutChart({totalOfflineChargers,totalOnlineChargers}) {

    
    
    const data = {
        labels: ['Online', 'Offline'],
        datasets: [{
            label: 'Poll',
            data: [totalOnlineChargers, totalOfflineChargers],
            backgroundColor: ['#99EFCB', ' #F84F56'],
            borderColor: ['#99EFCB', ' #F84F56'],
            outerradius:'100%',
                innerradius: "99%",
                cornerradius:'7%',
                padangle:'10'
        }]
    }
    const option = {
        responsive: true,
        maintainAspectRatio: true,
        cutout: 45,
        plugins: {
            legend: {
                position: 'right'
            }
        }
    }
    const textcenter = {
        id: "textcenter",
        beforeDatasetsDraw(chart,args,plusginOptions){
          const{ctx,data} = chart;
          ctx.save();
          ctx.font = 'bolder 40px Roboto';
          ctx.fillStyle = 'black';
          ctx.textAlign = 'center';
          ctx.textBaseline = 'middle';
          ctx.fillText(totalOfflineChargers+totalOnlineChargers,chart.getDatasetMeta(0).data[0].x,chart.getDatasetMeta(0).data[0].y)
    
        }
      }
      
    return (
        <div style={{height: '230px',
            width: "230px",marginTop:"-23px",  }}>
            <Doughnut data={data} options={option} width={300} height={300} plugins={[textcenter]}>
            </Doughnut>
        </div>
    )
}
export default DoughnutChart;