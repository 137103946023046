import { Link } from "react-router-dom";
function StationCard({data , changeMapCenter,changeSelectedStation,}){
    function updateMap(){
        changeSelectedStation(data);
        changeMapCenter({lat:data.latitude,lng: data.longitude});
    }
   
    return (
        <Link onClick={updateMap} to={`/dashboard/charging_stations/${data.id}`}>
        <section  id="station-card" className="flex">
            <img src="/station.png" alt="" />
            <div id="card-body">
                <ul>
                    <h3>{data.name}</h3>
                    <div className="address-container">
                        <small id="card-address">{data.address}</small>
                    </div>
                </ul>
                <ul id="body-details">
                    <div>   
                        <span className="material-symbols-outlined">schedule</span>
                        <small>Open 24/7</small>
                    </div>
                    <div>
                    <span className="material-symbols-outlined">
                            payments
                            </span>
                         <small>
                         ₹{data.price}/unit
                        </small> 
                    </div>
                </ul>

                {data.avalibility ? (
                <div className="avalibility-btn">
                    Available
                    </div>
                ) : (
                    <div className="non-avalibility-btn ">
                    Unavailable
                    </div>
                )}
                <div id="rating-div">
                    <div style={{fontSize:"16px"}}>{data.rate}
                    <span className="material-symbols-outlined" style={{fontSize:"16px"}}>
                        star
                        </span>
                        <small id="rating-small" style={{fontSize:"16px"}}>| {data.reviews.length} Reviews
                        </small>
                         </div>
                </div>
            </div>
        </section>
        </Link>
    );
}

export default StationCard;