import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';

const DropDownProfile = () => {

  const [open, setOpen] = useState(false)
  const menuRef = useRef()
  const clickRef = useRef()
  window.addEventListener('click', (e) => {
    if (e.target !== menuRef.current && e.target !== clickRef.current) {
      setOpen(false)
    }
  })

  return (
    <div>


      <div className='relative inline-block text-left'>
        <div ><img ref={clickRef} onClick={() => { setOpen(!open) }} className='px-3 h-2 cursor-pointer' src="/expand_more.png" alt="expandmore-icon" /></div>
        {open && (
          <div className='customdropdown pb-4 absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1"' ref={menuRef}>
            <div className='profile-imgblock'>
              <img src="/profile-img.png" alt="" />
            </div>
            <div className='profile-imgblock-content'>
              <h3>Ajay Kumar</h3>
              <div className='smalltext mb-4'>Admin</div>
            </div>

            <div className='custom-sectionbordertop'>
              <ul>
                <li onClick={() => { setOpen(false) }}>

                  <Link to='/profile' class="text-gray-700 block px-4 flex items-center py-1 text-sm" role="menuitem" tabindex="-1" id="menu-item-0">
                    <span class="me-3 material-symbols-outlined">
                      favorite
                    </span>

                    My profile</Link>
                </li>
                <li>
                  <Link to='/profile' class="text-gray-700 block px-4 flex items-center py-1 text-sm" role="menuitem" tabindex="-1" id="menu-item-0">
                    <span class="me-3 material-symbols-outlined">
                      settings
                    </span>
                    Profile Setting</Link>
                </li>
              </ul>
            </div>
            <div className='custom-sectionbordertop'>
              <ul>
                <li onClick={() => { setOpen(false) }}>
                  <Link to="/" class="text-gray-700 block px-4 flex items-center py-1 text-sm" role="menuitem" tabindex="-1" id="menu-item-0">
                    <span class=" me-3 material-symbols-outlined">
                      logout
                    </span>
                    Logout</Link>
                </li>

              </ul>
            </div>
          </div>

        )}
      </div>
    </div>
  );
}

export default DropDownProfile;
