import React from 'react'
import UsersDemoData from '../../Demodata/UsersData';
const AddUserPage3 = ({setAddUserPage3,addedUserId}) => {
    const handleOnClose=()=>{
                 setAddUserPage3(false);
    }
    let AddedUserInfo;
    UsersDemoData.forEach(user => {
        for (const key in user) {
            if(user[key]===addedUserId){
              AddedUserInfo = user;  
                
            }
        }
    });
  
  return (
    <div>
      <div className="inset-0 fixed bg-primaryGrey bg-opacity-25 flex justify-center items-center" style={{zIndex:"100"}}>
                <div className='w-[900px] h-[600px] rounded-xl overflow-hidden bg-white' id='Modal'>
                    <div className="flex justify-between items-center pt-3 pr-5 pl-5 pb-3" style={{ backgroundColor: "#EBF1F6" }}>

                        <div className="" style={{
                            fontWeight: 600,
                            fontSize: "20px"
                        }}>Add New User</div>
                        <div className="">
                            <span className="material-symbols-outlined cursor-pointer" style={{
                                fontWeight: 600,
                                fontSize: "40px"
                            }} onClick={handleOnClose} >
                                close
                            </span>
                        </div>
                    </div>
                    <div className="flex   gap-[1rem] p-10">
                        <div className="left">
                            <div className=" p-5 bg-[#F6F6F6] w-[300px] h-[230px]">
                                <div className=" mb-3" style={{ fontSize: "16px", fontWeight: "600" }}>Follow these steps to add a new car</div>
                                <div className="flex ">

                                    <div className="InActiveIcon">1</div>
                                    <div className="" style={{ color: "#86868B",  fontSize: "14px" }}>Basic User Info</div>
                                </div>
                                <div className="lineIcon"></div>
                                <div className="flex ">
                                    <div className="InActiveIcon">2</div>
                                    <div className="" style={{ color: '#86868B',fontSize: "14px"  }}>Authenticate User</div>
                                </div>
                                <div className="lineIcon"></div>
                                <div className="flex">

                                    <div className="ActiveIcon">3</div>
                                    <div className="" style={{ fontWeight: "600",color: '#0078D7',fontSize: "14px"  }}>Complete</div>
                                </div>
                            </div>
                        </div>
                      <div className="right">
                        <div className=" p-7 rounded-lg h-[230px]" style={{border:"1px solid #CBD4C8"}}>
                            <div className="header gap-4 flex w-[100%] justify-center">
                                <img className='h-[40px] w-[40px]' src="/verified.png" alt="verified" />
                                <div className="" style={{fontSize:"20px", fontWeight:"600" ,marginBottom:"20px"}}>User has been added successfully</div>
                            </div>
                            <div className="flex justify-center items-center ">
                                <div className=""><img src="/userimg.png" alt="profile" style={{width:"90px",height:"90px" ,marginRight:"20px"}} /></div>
                                <div className="flex gap-3">
                                <span className="flex flex-col w-[140px]">
                                    <span className="Name" style={{fontSize:"16px", color:"black",fontWeight:"400"}}>{AddedUserInfo.UserName}</span>
                                    <span className="number" style={{fontSize:"16px", color:"black",fontWeight:"400"}}>{AddedUserInfo.mobileNumber}</span>
                                    <span className="role" style={{fontSize:"16px", color:"black",fontWeight:"400"}}>{AddedUserInfo.UserRole}</span>
                                </span>
                                <div className="newUserInfoSeparator"></div>
                                <div className="flex flex-col gap-[5px]">
                                <div className="UserId" style={{fontSize:"16px", color:"black",fontWeight:"400"}}>UserId:{AddedUserInfo.UserId}</div>
                                    <div className="CreatedOn" style={{fontSize:"16px", color:"black",fontWeight:"400"}}>Created:{AddedUserInfo.CreatedOn}</div>
                                    
                                </div>
                                </div>
                            </div>
                            <div className="flex justify-end">

                            <div className=" mt-4 cursor-pointer" style={{fontSize:"18px",fontWeight:"600",color:"#0078D7"}}onClick={()=>setAddUserPage3(false)}>Go Back to homepage</div>
                            </div>
                        </div>
                      </div>
                    </div>
                </div>
            </div>
    </div>
  )
}

export default AddUserPage3
