import React from 'react'

import {Link,useLocation} from "react-router-dom"
import Charging_performance_modal from './Modals/Charging_performance_modal';

function Navbar() {
  
  const location = useLocation();
  return (
    <section id = "nav-section" className=''>
    <div id= "side-nav" className=' fixed h-full bottom-0 top-0 bg-white flex flex-col justify-between box-border'>
      <div className="top-section">
        {/* Company logo */}
        <div className='flex pt-7 pe-5 ps-5 pb-2 justify-center items-center'>
          <img className='h-8 mx-2' src="/dashboard-logo.png" alt="company-logo" />
          <span id="nav-heading" className='text-primaryBlue font-Quantico font-bold'>MYeKIGAI</span>
        </div>

        {/* Nav-links */}
        <div className="nav-links flex flex-col p-5 gap-3 ">
      
            <Link to="/dashboard" className={location.pathname === "/dashboard" ? "active-mobile" : ""}>
              <div className='flex p-4 items-center rounded-lg cursor-pointer'>
              <span className="h-5 mx-3 material-symbols-outlined">
                dashboard
                </span>
                  <h1 className='font-medium tracking-tight text-sm'>Admin Dashboard</h1>
              </div>
            </Link>

            <Link to="/dashboard/charging_stations" className={location.pathname.startsWith("/dashboard/charging_stations")? "active-mobile" : ""}>
          <div className='flex p-4 items-center cursor-pointer'>
          <span className=" h-5 mx-3 material-symbols-outlined">
            ev_station
            </span>
              <h1 className='font-medium tracking-tight text-sm '>Charging Station</h1>
          </div>
            </Link>

            <Link to="/dashboard/access" className={location.pathname.startsWith("/dashboard/access")? "active-mobile" : ""}>
          <div className='flex p-4 items-center cursor-pointer'>
          <span className="h-5 mx-3 material-symbols-outlined">
            lock
            </span>
              <h1 className='font-medium tracking-tight text-sm '>Access</h1>
          </div>
            </Link>

            <Link to="/dashboard/transactions" className={location.pathname.startsWith("/dashboard/transactions")? "active-mobile" : ""}>
          <div className='flex p-4 items-center cursor-pointer'>
          <span className="h-5 mx-3 material-symbols-outlined">
            history
            </span>
              <h1 className='font-medium tracking-tight text-sm '>Transactions</h1>
          </div>
            </Link>

            <Link to="/dashboard/ticket" className={location.pathname.startsWith("/dashboard/ticket")? "active-mobile" : ""}>
          <div className='flex p-4 items-center cursor-pointer relative'>
          <span className="h-5 mx-3 material-symbols-outlined">
            confirmation_number
            </span>
              <h1 className='font-medium tracking-tight text-sm '>Ticket Status</h1>
              <p className='bg-secondaryGreen rounded-lg absolute right-6 text-xs p-1'>20+</p>
          </div>
            </Link>

        </div>
      </div>
      {/* logout section */}
      <div className="bottom-section">
        <div className='flex bg-green-400 p-4 items-center cursor-pointer '>
          
        <Link to="/dashboard/Help" >
          <h1 className='font-medium flex tracking-tight text-sm text-[#535151]'>
            <span className="h-6 mx-3  material-symbols-outlined">
            contact_support
            </span>Help</h1>
            </Link>
        </div>
        <div className='flex bg-green-400 p-4 items-center cursor-pointer mb-8'>
          <span className="h-5 mx-3 material-symbols-outlined">
            logout
            </span>
          <Link to="/">
            <h1 className='font-medium tracking-tight text-sm text-[#535151]'>Logout</h1>
          </Link>
        </div>
      </div>
    </div>

    </section>
  )
}

export default Navbar;