import { useEffect, useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useLocation, useNavigate, Link } from "react-router-dom";
import Report from "../components/Modals/Report";
import DoughnutChart from "../components/DougnutChart";
import DoughnutChart2 from "../components/DougnutChart2";
import MainContainer from "../components/MainContainer";
import DashboardNavigator from "../components/DashboardNavigator";
import TotalSessionCardDemoData from "../Demodata/TotalSessionCardData";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import EnergyConsumptionCard from "../components/AdminDashboardComponents/EnergyConsumptionCard";
import ReactPaginate from "react-paginate";
import Card from "../pages/Card";
import TotalSessionCard from "../components/AdminDashboardComponents/TotalSessionCard";
import UserReviewCard from "../components/UserAnalytics/UserReviewCard";
import StationRating from "../components/UserAnalytics/StationRating";

import ChargerCard from "../components/ChargerPerformance/ChargerCard";
import OfflineChargerCard from "../components/ChargerPerformance/OfflineChargerCard";

import Slider from "react-slick";
import AllReviewsModal from "../components/Modals/AllReviewsModal";
function generateRandomNumber(digits) {
  if (digits <= 0) {
    throw new Error("Number of digits should be greater than 0");
  }

  const min = 10 ** (digits - 1);
  const max = 10 ** digits - 1;

  return Math.floor(Math.random() * (max - min + 1)) + min;
}
function Station() {
  const location = useLocation();

  const [previousSelectedOption, setPreviousSelectedOption] =
    useState("Last 30 days");
  const [total_revenue, setTotalRevenue] = useState(generateRandomNumber(5));
  const [currentStation, setCurrentStation] = useState(null);
  const [totalOnlineChargers, setTotalOnlineChargers] = useState(0);
  const [totalOfflineChargers, setTotalOfflineChargers] = useState(0);
  const [totalChargingChargers, setTotalChargingChargers] = useState(0);
  const [totalUnderMaintenanceChargers, setTotalUnderMaintenanceChargers] =
    useState(0);
  const [totalIdleChargers, setTotalIdleChargers] = useState(0);
  const [totalSessionCardDemoData, setTotalSessionCardDemoData] = useState([]);
  const [stationData, setStationData] = useState([]);
  const [onlineChargerDetails, setOnlineChargerDetails] = useState([]);
  const [offlineChargerDetails, setOfflineChargerDetails] = useState([]);
  const [listOfOfflineCharger, setListOfOfflineCharger] = useState([]);
  const [energyConsumptionDemoData, setEnergyConsumptionDemoData] = useState(
    []
  );
  const [userTrafficData, setUserTrafficData] = useState([]);
  const [uniqueCustomer, setUniqueCustomer] = useState([]);
  const [sessionsPerStation, setSessionsPerStation] = useState(0);
  const [showAllReviewsModal, setShowAllReviewsModal] = useState(false);
  const [offlineChargerData, setOfflineChargerData] = useState([]);
  const fetchdata = () => {
    const pathnameSegments = location.pathname.split("/");
    // Get the last segment
    const lastSegment = pathnameSegments[pathnameSegments.length - 1];

    fetch("/JsonData/OfflineChargerData.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        setOfflineChargerData(result);
      });

    fetch("/JsonData/uniqueCustomer.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        setUniqueCustomer(result);
      });

    fetch("/JsonData/energy.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        setEnergyConsumptionDemoData(result);
      });
    //    console.log(energyConsumptionDemoData);

    fetch("/JsonData/traffic.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        setUserTrafficData(result);
      });
    //    console.log(userTrafficData);
    //  console.log(totalSessionCardDemoData,userTrafficData);

    fetch("/JsonData/stationsData1.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        setStationData(result);
        result.forEach((e) => {
          if (e.id.toString() === lastSegment) {
            setCurrentStation(e);
            let cnt = 0;
            e.chargers.filter((e) => (e.sessions ? cnt++ : "n"));
            setSessionsPerStation(cnt);
          }
        });
        result.forEach((e) => {
          let totalSession = [
            {
              monday: 0,
              tuesday: 0,
              wednesday: 0,
              thrusday: 0,
              friday: 0,
              saturday: 0,
              sunday: 0,
            },
          ];
          if (e.id.toString() === lastSegment) {
            setTotalOnlineChargers(
              e.chargers.filter((e) => e.chargerStatus === "online").length
            );
            setTotalOfflineChargers(
              e.chargers.filter((e) => e.chargerStatus === "offline").length
            );
            setTotalChargingChargers(
              e.chargers.filter((e) => e.chargingStatus === "charging").length
            );
            setTotalIdleChargers(
              e.chargers.filter((e) => e.chargingStatus === "idle").length
            );
            setTotalUnderMaintenanceChargers(
              e.chargers.filter((e) => e.chargingStatus === "undermaintenance")
                .length
            );
            e.chargers.map((e) =>
              e.sessions?.map((e) => {
                return (
                  totalSession.map((p) => (p.monday += e.monday)),
                  totalSession.map((p) => (p.tuesday += e.tuesday)),
                  totalSession.map((p) => (p.wednesday += e.wednesday)),
                  totalSession.map((p) => (p.thrusday += e.thrusday)),
                  totalSession.map((p) => (p.friday += e.thrusday)),
                  totalSession.map((p) => (p.friday += e.friday)),
                  totalSession.map((p) => (p.saturday += e.saturday)),
                  totalSession.map((p) => (p.sunday += e.sunday))
                );
              })
            );
            setTotalSessionCardDemoData(totalSession);
            setOnlineChargerDetails(
              e.chargers.filter((e) => {
                return e.chargerStatus === "online";
              })
            );
            setOfflineChargerDetails(
              e.chargers.filter((e) => {
                return e.chargerStatus === "offline";
              })
            );
            setListOfOfflineCharger(
              e.chargers.filter((e) => {
                return e.chargerStatus === "offline";
              })
            );
          }
        });
      });
  };

  useEffect(() => {
    fetchdata();
  }, [location.pathname]);



  const OnlineChargerCardsPerPage = 3;
  const [currentOnlineChargerCardPage, setCurrentOnlineChargerCardPage] =
    useState(0);
  const OnlineChargerCardstartIndex =
    currentOnlineChargerCardPage * OnlineChargerCardsPerPage;
  const OnlineChargerendIndex =
    OnlineChargerCardstartIndex + OnlineChargerCardsPerPage;
  const handleOnlineChargerPageClick = (selectedPage) => {
    setCurrentOnlineChargerCardPage(selectedPage.selected);
  };
  const [filteredOnlineChargerCard, setFilteredOnlineChargerCard] =
    useState(onlineChargerDetails);

  //    console.log(filteredOnlineChargerCard);

  const currentOnlineChargerCard = onlineChargerDetails.slice(
    OnlineChargerCardstartIndex,
    OnlineChargerendIndex
  );
  // console.log(currentOnlineChargerCard);

  //Offline Charger Pagination Details
  const OfflineChargerOnlineChargerCardsPerPage = 3;
  const [currentOfflineChargerPage, setCurrentOfflineChargerPage] = useState(0);
  const OfflineChargerOnlineChargerCardstartIndex =
    currentOfflineChargerPage * OfflineChargerOnlineChargerCardsPerPage;
  const OfflineChargerOnlineChargerendIndex =
    OfflineChargerOnlineChargerCardstartIndex +
    OfflineChargerOnlineChargerCardsPerPage;
  const handleOfflineChargerPageClick = (selectedPage) => {
    setCurrentOfflineChargerPage(selectedPage.selected);
  };

  const currentOfflineChargerCard = offlineChargerData.slice(
    OfflineChargerOnlineChargerCardstartIndex,
    OfflineChargerOnlineChargerendIndex
  );

  //Pagination In Faulty Card Under Fault Tab
  const FaultyChargerOnlineChargerCardsPerPage = 3;
  const [currentFaultyChargerPage, setCurrentFaultyChargerPage] = useState(0);
  const FaultyChargerOnlineChargerCardstartIndex =
    currentFaultyChargerPage * FaultyChargerOnlineChargerCardsPerPage;
  const FaultyChargerOnlineChargerendIndex =
    FaultyChargerOnlineChargerCardstartIndex +
    FaultyChargerOnlineChargerCardsPerPage;
  const handleFaultyChargerPageClick = (selectedPage) => {
    setCurrentFaultyChargerPage(selectedPage.selected);
  };

  const currentFaultyChargerCard = listOfOfflineCharger.slice(
    FaultyChargerOnlineChargerCardstartIndex,
    FaultyChargerOnlineChargerendIndex
  );

  // Report Card Pagination Details
  const CardsPerPage = 2;
  const [currentPage, setCurrentPage] = useState(0);
  const [energySaved, setEnergySaved] = useState(149);
  const [sessionsCompleted, setSessionsCompleted] = useState(7);
  const startIndex = currentPage * CardsPerPage;
  const endIndex = startIndex + CardsPerPage;
  const handlePageClick = (selectedPage) => {
    setCurrentPage(selectedPage.selected);
  };



  const handleOnSelectFaultyChargerReportDropDown = (p) => {
    let offlineCharger = [];
    currentStation.chargers
        .filter((incharger) => {
          return incharger.chargerStatus === "offline";
        })
        .map((charger) => {
          return offlineCharger.push({
            stationName: currentStation.name,
            stationAddress: currentStation.address,
            charger: charger,
          });
        })
    

    const SelectCardByTime = offlineCharger.filter((e) =>
      e.charger.lastUpdated.includes(p.target.value)
    );
    setOfflineChargerDetails(SelectCardByTime);
  };
  
  const currentCard = offlineChargerDetails
  .slice(startIndex, endIndex);
const totalReportsInOfflineCharger = offlineChargerDetails.filter(
  (e) => e.report
);

  const handleDropdownChange = (event) => {
    const newSelectedOption = event.target.value;
    setEnergySaved(energySaved+generateRandomNumber(3))
    setSessionsCompleted(sessionsCompleted+generateRandomNumber(1))
    // Compare previous and new selected options and update total_revenue accordingly
    // switch (true) {
    switch (true) {
      case (previousSelectedOption === "Last 30 days" &&
        newSelectedOption === "Last 6 months") ||
        (previousSelectedOption === "Last 30 days" &&
          newSelectedOption === "Last 1 year") ||
        (previousSelectedOption === "Last 30 days" &&
          newSelectedOption === "Last 3 year") ||
        (previousSelectedOption === "Last 30 days" &&
          newSelectedOption === "Last 5 year"):
        setTotalRevenue(total_revenue + generateRandomNumber(5));
        break;
      case (previousSelectedOption === "Last 6 months" &&
        newSelectedOption === "Last 1 year") ||
        (previousSelectedOption === "Last 6 months" &&
          newSelectedOption === "Last 3 year") ||
        (previousSelectedOption === "Last 6 months" &&
          newSelectedOption === "Last 5 year"):
        setTotalRevenue(total_revenue + generateRandomNumber(4));
        break;
      case (previousSelectedOption === "Last 1 year" &&
        newSelectedOption === "Last 3 year") ||
        (previousSelectedOption === "Last 1 year" &&
          newSelectedOption === "Last 5 year"):
        setTotalRevenue(total_revenue + generateRandomNumber(4));
        break;
      case previousSelectedOption === "Last 3 year" &&
        newSelectedOption === "Last 5 year":
        setTotalRevenue(total_revenue + generateRandomNumber(5));
        break;
      default:
        setTotalRevenue(total_revenue - generateRandomNumber(2));
        break;
    }

    // Update the currently selected option
    setPreviousSelectedOption(newSelectedOption);
  };

  var settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
  };
  const navigate = useNavigate();
  //   console.log(offlineChargerDetails);
  const handleOnDelete = (id) => {
    const index = offlineChargerDetails.findIndex((e) => {
      return id === e._id;
    });

    navigate(location.pathname);
    offlineChargerDetails.splice(index, 1);
  };
  const [numberOfOnlineCharger, setNumberOfOnlineCharger] = useState(
    totalChargingChargers
  );
  const [numberOfOfflineCharger, setNumberOfOfflineCharger] =
    useState(totalOfflineChargers);
  const handleOnChangeTotalOnlineChargerPeriod = (e) => {
    if (e.target.value === "Last 24 hours") {
      setNumberOfOnlineCharger(totalChargingChargers);
      setNumberOfOfflineCharger(totalOfflineChargers);
    } else {
      setNumberOfOfflineCharger(generateRandomNumber(1));
      setNumberOfOnlineCharger(generateRandomNumber(1));
    }
  };
  const [showReport, setShowReport] = useState(false);
  const [reportDetails, setReportDetails] = useState(false);
  return (
    <main className="bg-[#EBF3F9] reponsive2 p-5 h-[100%] ">
      {currentStation ? (
        <>
          <DashboardNavigator
            pageName={"Charging Stations"}
            custom_path={`Dashboard > Charging Stations > ${currentStation.name}`}
          />
          <div
            className={" responsive w-[auto]  bg-white  rounded-md "}
          >
            <div className="main-charging-station-container box-border flex flex-col p-5 mb-11 ">
              <div id="charger-heading" className=" flex justify-between">
                <h2 className="text-[#212B36] mb-4">{currentStation.name}</h2>
                <Link to="/dashboard/charging_stations">
                  <span className="material-symbols-outlined cursor-pointer">
                    close
                  </span>
                </Link>
              </div>
              <Tabs selectedTabClassName="Active">
                <TabList className="tablist">
                  <Tab className="tab">Charger Performance</Tab>
                  <Tab className="tab">Energy Management</Tab>
                  <Tab className="tab">Fault Detection</Tab>
                  <Tab className="tab">User Analytics</Tab>
                </TabList>
                <div className="tabs_underline"></div>
                <TabPanel>
                  <div className="charging-station-details flex justify-between my-4 mt-7">
                    <div className="charging-station h-[210px] border border-[#CBD4C8] rounded-lg flex justify-between p-5 w-[38%] cursor-pointer shadow-sm">
                      <div className="charging-station-image w-[298px] ">
                        <img src="/station.png" alt="charging station img" />
                      </div>
                      <div id="card-body">
                        <ul>
                          <h3 style={{ fontSize: "16px", fontWeight: "600" }}>
                            {currentStation.name}
                          </h3>
                          <div className="address-container font-bold text-sm text-secondaryGrey">
                            <small id="card-address-short">
                              {currentStation.address}
                            </small>
                          </div>
                        </ul>
                        <ul id="body-details" className=" mb-5 mt-5 ">
                          <div className=" ">
                            <span
                              className="material-symbols-outlined"
                              style={{ fontSize: "14px" }}
                            >
                              schedule
                            </span>
                            <small className="" style={{ fontSize: "14px" }}>
                              Open 24/7
                            </small>
                          </div>
                          <div>
                            <span
                              className="material-symbols-outlined"
                              style={{ fontSize: "14px" }}
                            >
                              payments
                            </span>
                            <small style={{ fontSize: "14px" }}>
                              ₹{currentStation.price}
                            </small>
                          </div>
                        </ul>

                        {currentStation.avalibility ? (
                          <div className="avalibility-btn mb-5">Available</div>
                        ) : (
                          <div className="non-avalibility-btn mb-5">
                            Unavailable
                          </div>
                        )}
                        <div
                          id="rating-div"
                          className="mb-20px"
                          style={{ fontSize: "15px" }}
                        >
                          <div>
                            4.8
                            <span
                              className="material-symbols-outlined"
                              style={{ fontSize: "20px" }}
                            >
                              star
                            </span>
                            
                            <small
                              id="rating-small"
                              style={{
                                fontSize: "15px",
                                textDecoration: "underline",
                              }}
                               onClick={()=>setShowAllReviewsModal(true)}>
                              | {currentStation.reviews.length} Reviews
                            </small>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="revenue w-[60%] h-[210px] border border-[#CBD4C8] rounded-lg flex flex-col py-4 px-8 cursor-pointer jus">
                    <div className="options mb-6">
                                <select onChange={handleDropdownChange} name="total_revenue" id="total-revenue" className='bg-[#DFE3E8] rounded-md p-1 outline-none'>
                                    <option value="Last 30 days">Last 30 days</option>
                                    <option value="Last 6 months">Last 6 months</option>
                                    <option value="Last 1 year">Last 1 year</option>
                                    <option value="Last 3 year">Last 3 year</option>
                                    <option value="Last 5 year">Last 5 year</option>
                                </select>
                                </div>
                      <div className="flex w-[100%] justify-between">
                        <div className="flex flex-col items-center">
                          <h4
                            style={{
                              fontWeight: "500",
                              fontSize: "16px",
                              color: "gray",
                            }}
                          >
                            Total revenue generated
                          </h4>
                          <div
                            className=" font-semibold text-[#212B36]"
                            style={{ fontSize: "27px", marginTop: "10px" }}
                          >
                            ₹ {total_revenue}
                          </div>
                        </div>
                        <div className="revenue_session_energy_separator"></div>
                        <div className="flex flex-col items-center">
                          <h4
                            style={{
                              fontWeight: "500",
                              fontSize: "16px",
                              color: "gray",
                            }}
                          >
                            Sessions Completed
                          </h4>
                          <div
                            className="text-3xl font-semibold text-[#212B36]"
                            style={{ fontSize: "27px", marginTop: "10px" }}
                          >
                            {sessionsCompleted}
                          </div>
                        </div>
                        <div className="revenue_session_energy_separator"></div>
                        <div className="flex flex-col items-center">
                          <h4
                            style={{
                              fontWeight: "500",
                              fontSize: "16px",
                              color: "gray",
                            }}
                          >
                            Energy Saved
                          </h4>
                          <div
                            className="text-3xl font-semibold text-[#212B36]"
                            style={{ fontSize: "27px", marginTop: "10px" }}
                          >
                            {energySaved} kWh
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="flex">
                    <div className="border border-[#CBD4C8] rounded-lg w-[22%] h-[16rem] mb-5 p-4 cursor-pointer">
                      <h3
                        className="pl-4"
                        style={{
                          fontWeight: 600,
                          fontSize: "20px",
                        }}
                      >
                        Charger Status
                      </h3>
                      <DoughnutChart
                        totalOfflineChargers={totalOfflineChargers}
                        totalOnlineChargers={totalOnlineChargers}
                      />
                    </div>
                    <div className="border border-[#CBD4C8] rounded-lg w-[33%] h-[16rem] mb-5 ml-5 p-4 cursor-pointer">
                      <h3
                        className="pl-4"
                        style={{
                          fontWeight: 600,
                          fontSize: "20px",
                        }}
                      >
                        Online Charger Status
                      </h3>
                      <DoughnutChart2
                        totalChargingChargers={totalChargingChargers}
                        totalUnderMaintenanceChargers={
                          totalUnderMaintenanceChargers
                        }
                        totalIdleChargers={totalIdleChargers}
                      />
                    </div>
                    <div className="border border-[#CBD4C8] rounded-lg w-[42%] h-[16rem] mb-5 ml-5 p-2 cursor-pointer">
                      <TotalSessionCard
                        height={"auto"}
                        CardName={"Total Session"}
                        barcolor="#00D77D"
                        propsData={TotalSessionCardDemoData}
                        aspectRatio={4.2}
                      />
                    </div>
                  </div>

                  <div className="online-charger-container border border-[#CBD4C8] rounded-lg flex flex-col p-4 shadow-sm">
                    <h2
                      className="pl-4"
                      style={{ fontWeight: "600", fontSize: "22px" }}
                    >
                      Online Chargers
                    </h2>
                    <div className="flex">
                      {/* {console.log(currentOnlineChargerCard)} */}
                      {currentOnlineChargerCard.map((e, ind) => {
                        return (
                          <ChargerCard
                            ChargerState={e.chargingStatus}
                            ChargerHealth={e.chargerHealth}
                            ChargerSerialNumber={e._id}
                            key={e._id}
                            ModalData={e.ModalData}
                          />
                        );
                      })}
                    </div>
                    <div className="pages flex items-center justify-end pr-3">
                      <ReactPaginate
                        breakLabel="..."
                        nextLabel=">"
                        onPageChange={handleOnlineChargerPageClick}
                        pageRangeDisplayed={5}
                        pageCount={Math.ceil(
                          onlineChargerDetails.length /
                            OnlineChargerCardsPerPage
                        )}
                        previousLabel="<"
                        containerClassName="pagination flex justify-center gap-4"
                        pageClassName="w-6 h-6 flex justify-center items-center"
                        pageLinkClassName="text-sm font-semibold "
                        activeClassName="bg-[#0078D7] rounded text-[#FFFFFF]"
                        previousLinkClassName="text-[#0078D7] font-bold"
                        nextLinkClassName="text-[#0078D7] font-bold"
                      />
                    </div>
                  </div>

                  <div className="offline-charger-container border border-[#CBD4C8] rounded-lg flex flex-col p-4 shadow-sm mt-6">
                    <h2
                      className="pl-4"
                      style={{ fontSize: "20px", fontWeight: "600" }}
                    >
                      Offline Chargers
                    </h2>
                    <div className="flex">
                      {currentOfflineChargerCard.map((e, ind) => {
                        return (
                          
                          <OfflineChargerCard
                            SerialNumber={e.SerialNumber}
                            OnlineTime={e.OnlineTime}
                            key={e.SerialNumber}
                          />
                        );
                      })}
                    </div>
                    <div className="pages flex items-center justify-end pr-3">
                      <ReactPaginate
                        breakLabel="..."
                        nextLabel=">"
                        onPageChange={handleOfflineChargerPageClick}
                        pageRangeDisplayed={5}
                        pageCount={Math.ceil(
                          offlineChargerData.length /
                            OfflineChargerOnlineChargerCardsPerPage
                        )}
                        previousLabel="<"
                        containerClassName="pagination flex justify-center gap-4"
                        pageClassName="w-6 h-6 flex justify-center items-center"
                        pageLinkClassName="text-sm font-semibold "
                        activeClassName="bg-[#0078D7] rounded text-[#FFFFFF]"
                        previousLinkClassName="text-[#0078D7] font-bold"
                        nextLinkClassName="text-[#0078D7] font-bold"
                      />
                    </div>
                  </div>
                </TabPanel>
                <TabPanel>
                  <div className="h-[470px]">
                    <EnergyConsumptionCard
                      duration={"30 days"}
                      aspectRatio={3.5}
                      propsData={energyConsumptionDemoData}
                    />
                  </div>
                </TabPanel>
                <TabPanel>
                  <div className="main p-10">
                    <div className="flex justify-between">
                      <div className="left w-[40%]">
                        <div
                          className="w-[100%] h-[240px]"
                          style={{
                            paddingRight: "20px",
                            paddingLeft: "30px",
                            paddingTop: "30px",

                            border: "2px solid #CBD4C8",
                            borderRadius: "20px",
                          }}
                        >
                          <div className="options mb-6">
                            <select
                              name="total_revenue"
                              id="total-revenue"
                              className="bg-[#DFE3E8] rounded-md p-1 outline-none"
                              onChange={handleOnChangeTotalOnlineChargerPeriod}
                            >
                              <option value="Last 24 hours">
                                Last 24 hours
                              </option>
                              <option value="Last 2 days">Last 2 days</option>
                              <option value="Last 5 days">Last 5 days</option>
                              <option value="Last 7 days">Last 7 days</option>
                            </select>
                          </div>
                          <div className="m-10"></div>
                          <div className="flex justify-between">
                            <div className="flex flex-col justify-center items-center">
                              <div
                                className=""
                                style={{ fontWeight: "600", fontSize: "16px" }}
                              >
                                Total Online Chargers
                              </div>
                              <div
                                className=""
                                style={{ fontWeight: "600", fontSize: "31px" }}
                              >
                                {numberOfOnlineCharger === 0
                                  ? totalChargingChargers
                                  : numberOfOnlineCharger}
                              </div>
                            </div>
                            <div className="Online_Faulty_Chargers_separator"></div>
                            <div className="flex flex-col justify-center items-center">
                              <div
                                className=""
                                style={{
                                  fontWeight: "600",
                                  fontSize: "16px",
                                  color: "#F84F56",
                                }}
                              >
                                Total Faulty Chargers
                              </div>
                              <div
                                className=""
                                style={{
                                  fontWeight: "600",
                                  fontSize: "31px",
                                  color: "#E40000",
                                }}
                              >
                                {numberOfOfflineCharger === 0
                                  ? totalOfflineChargers
                                  : numberOfOfflineCharger}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div
                          className="List_of_Faulty_Chargers"
                          style={{
                            marginTop: "25px",
                            paddingTop: "25px",
                            paddingBottom: "25px",
                            paddingRight: "30px",
                            border: "2px solid #CBD4C8",
                            width: "100%",
                            borderRadius: "20px",
                          }}
                        >
                          <div
                            className=" ml-5"
                            style={{ fontSize: "25px", fontWeight: "600" }}
                          >
                            List of Faulty Charger
                          </div>
                          <div className="">
                            {currentFaultyChargerCard.map((e) => {
                              return (
                                <ChargerCard
                                  ChargerHealth={"damaged"}
                                  ChargerState={"Under Maintenance"}
                                  ChargerSerialNumber={e._id}
                                  key={e._id}
                                  ModalData={e.ModalData}
                                />
                              );
                            })}

                            <ReactPaginate
                              breakLabel="..."
                              nextLabel=">"
                              onPageChange={handleFaultyChargerPageClick}
                              pageRangeDisplayed={5}
                              pageCount={Math.ceil(
                                listOfOfflineCharger.length /
                                  FaultyChargerOnlineChargerCardsPerPage
                              )}
                              previousLabel="<"
                              containerClassName="pagination flex justify-center gap-4"
                              pageClassName="w-6 h-6 flex justify-center items-center"
                              pageLinkClassName="text-sm font-semibold "
                              activeClassName="bg-[#0078D7] rounded text-[#FFFFFF]"
                              previousLinkClassName="text-[#0078D7] font-bold"
                              nextLinkClassName="text-[#0078D7] font-bold"
                            />
                          </div>
                        </div>
                      </div>
                      <div
                        className="right p-4 w-[58%] "
                        style={{
                          border: "2px solid #C1C1C1",
                          borderRadius: "20px",
                          backgroundColor: "#F8F9FA",
                          height: "fit-content",
                        }}
                      >
                        <div className="right w-[100%]  ">
                          <div className=" bg-white p-5 rounded-lg">
                            <div className="flex justify-between mb-5 ">
                              <h3 className="font-semibold text-xl">
                                Recently Generated Reports for faulty Chargers
                              </h3>
                              <div className="options mb-6">
                                <select
                                  name="total_revenue"
                                  id="total-revenue"
                                  className="bg-[#DFE3E8] rounded-md p-1 outline-none"
                                  onChange={
                                    handleOnSelectFaultyChargerReportDropDown
                                  }
                                >
                                  <option value="Last 24 hours">
                                    Last 24 hours
                                  </option>
                                  <option value="Last 2 days">
                                    Last 2 days
                                  </option>
                                  
                                </select>
                              </div>
                            </div>

                            <div className="bottom-part flex flex-col gap-4">
                              <div className="bottom-part flex flex-col gap-4">
                              
                                {currentCard.map((e) => {
                                  return (
                                    <div key={e._id}>
                                    <Card
                                     
                                     details={e}
                                     stationAddress={currentStation.address}
                                     stationName={currentStation.name}
                                     eleId={e._id}
                                     handleOnDelete={handleOnDelete}
                                     setShowReport={setShowReport}
                                     setReportDetails={setReportDetails}
                                     />
                                     </div>
                                  );
                                })}

                                <ReactPaginate
                                  breakLabel="..."
                                  nextLabel=">"
                                  onPageChange={handlePageClick}
                                  pageRangeDisplayed={5}
                                  pageCount={Math.ceil(
                                    offlineChargerDetails.length /
                                      CardsPerPage
                                  )}
                                  previousLabel="<"
                                  containerClassName="pagination flex justify-center gap-4"
                                  pageClassName="w-6 h-6 flex justify-center items-center"
                                  pageLinkClassName="text-sm font-semibold "
                                  activeClassName="bg-[#0078D7] rounded text-[#FFFFFF]"
                                  previousLinkClassName="text-[#0078D7] font-bold"
                                  nextLinkClassName="text-[#0078D7] font-bold"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </TabPanel>
                <TabPanel>
                  <div
                    className="Top w-[100%] rounded-lg flex"
                    style={{ border: "1px solid #CBD4C8" }}
                  >
                    <div
                      className="Station Ratings pr-10 pt-5 pl-10 h-[100%]"
                      style={{
                        fontSize: "20px",
                        fontWeight: "600",
                        color: "#454F5B",
                        paddingBottom: "30px",
                      }}
                    >
                      <StationRating />
                    </div>
                    <div className=""></div>
                    <div className="Reviews_rating_separator"></div>
                    <div className="Reviews pr-5 pb-10  pt-5 pl-10 w-[68%]">
                      <div className="flex w-[100%] justify-between">
                        <div className="flex flex-col">
                          <span
                            style={{
                              fontSize: "20px",
                              fontWeight: "600",
                              color: "#454F5B",
                            }}
                          >
                            Reviews
                          </span>
                          <span style={{ fontSize: "25px", fontWeight: "600" }}>
                            {currentStation.reviews.length}
                          </span>
                        </div>
                        <div
                          className="bg-primaryBlue cursor-pointer text-white px-3 py-1 rounded-lg h-[100%]"
                          onClick={() => {
                            setShowAllReviewsModal(true);
                          }}
                        >
                          View All
                        </div>
                      </div>
                      <div className="w-[680px]">
                        <Slider {...settings}>
                          {currentStation.reviews.map((e) => {
                            return (
                              <div key={e}>
                              <UserReviewCard
                                UserName={e.name}
                                rating={e.rate}
                                input={e.input}
                                date={e.date}
                                  key={e.name}
                                />
                                </div>
                            );
                          })}
                        </Slider>
                        {/* <UserReviewCard /> */}
                      </div>
                    </div>
                  </div>
                  <div className="flex h-[100%] gap-5">
                    <div
                      className="Bottom w-[60%] h-[100%] rounded-lg  mt-10"
                      style={{ border: "1px solid #CBD4C8" }}
                    >
                      <TotalSessionCard
                        CardName={"User traffic at station"}
                        barcolor={"#0078D7"}
                        propsData={userTrafficData}
                        aspectRatio={3.8}
                      />
                    </div>
                    <div
                      className="Bottom w-[40%] h-[100%] rounded-lg  mt-10"
                      style={{ border: "1px solid #CBD4C8" }}
                    >
                      <TotalSessionCard
                        CardName={"Unique Customers"}
                        barcolor={"#00D77D"}
                        propsData={uniqueCustomer}
                        aspectRatio={2.5}
                      />
                    </div>
                  </div>
                </TabPanel>
              </Tabs>
            </div>
          </div>
        </>
      ) : (
        <MainContainer>
          <div className="w-full h-full flex justify-center items-center min-h-52">
            <h1>Opps, No charger details found</h1>
          </div>
        </MainContainer>
      )}
      {showAllReviewsModal && (
        <AllReviewsModal
          setShowAllReviewsModal={setShowAllReviewsModal}
          currentStation={currentStation}
        />
      )}
      {showReport && (
        <Report ReportDetails={reportDetails} setShowReport={setShowReport} />
      )}
    </main>
  );
}

export default Station;
