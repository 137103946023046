import {React,useState} from 'react';
import Loader from "../components/loadingSpinner"
import {useNavigate} from "react-router-dom"
function Login() {
    const navigation = useNavigate();
    let [loading, setLoading] = useState(false);
    const login = (event) =>{
        event.preventDefault();
        setLoading(true);
        setTimeout(() => {
           setLoading(false);
           navigation("/dashboard")
        }, 2300);
    }
    return (
        <div className="bg-[#ADD4F2] h-screen pb-44 w-full flex flex-col justify-center items-center box-border">
            <section id='login'>
                <div className="company-details flex flex-col justify-center items-center gap-3">
                    <img className='w-20 ' src="logo.png" alt="company-logo" />
                    <h1 className='font-bold text-5xl tracking-wide font-Quantico'>MYeKIGAI</h1>
                    <h3 className='text-[#282828] font-extrabold text-base text-center font-Quantico tracking-tight mb-6'>#ForYouByMe</h3>
                </div>
                <form onSubmit={login} className='w-96 signup-form'>
                    <div className="flex flex-col mb-4">
                        <input className='p-2 rounded' type="email" id="username" name="username" placeholder='Login Id' required autoComplete='off' />
                    </div>
                    <div className="flex flex-col mb-4">
                        <input className='p-2 rounded' type="password" id="password" name="password" placeholder='Password' required autoComplete='off' />
                    </div>
                    <div className="flex flex-col mb-4">
                        <button  className='p-2 mt-2 text-white font-semibold rounded bg-[#0078D7CC] hover:bg-[#0078D7FF]' type="submit">Login</button>
                    </div>
                </form>
                <Loader loading={loading}/>
            </section>
        </div>
    )
}
function HomePage(){
    return(
        <Login/>
    );
}
export default HomePage;