import React from 'react'

const Status_btn = ({color,backgroundColor,status}) => {
  return (
    <div>
      <div className=" " style={{
          fontSize: "14px",
          fontWeight: "600",
          textAlign: "center",
          borderRadius: "8px",
          width: "100%",
          padding: "5px",
          color:color,
          backgroundColor:backgroundColor,
          marginRight:"10px"
        }}>
        {status}
      </div>
    </div>
  )
}

export default Status_btn
