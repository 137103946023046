import React, { useEffect, useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import PolicyAdd from "../components/Modals/PolicyAdd";
import AddUserPage1 from "../components/Modals/AddUserPage1";
import AddUserPage2 from "../components/Modals/AddUserPage2";
import AddUserPage3 from "../components/Modals/AddUserPage3";
import ReactPaginate from "react-paginate";

import UsersDemoData from "../Demodata/UsersData";
import ActionButton from "../components/ActionButton";
import PolicyUpdate from "../components/Modals/PolicyUpdate";
import { useNavigate } from "react-router-dom";
import UpdateUserInfo from "../components/Modals/UpdateUserInfo";
const Accsess = () => {
  const [addPolicy, setAddPolicy] = useState(false);
  const [addUser, setAddUser] = useState(false);
  const [addUserPage2, setAddUserPage2] = useState(false);
  const [addUserPage3, setAddUserPage3] = useState(false);
  const [addedUserId, setAddedUserId] = useState("");
  const [showActionButton, setShowActionButton] = useState(false);
  const [selectedPolicyName, setSelectedPolicyName] = useState("");
  const [selectedUser, setSelectedUser] = useState("");
  const [policyDetails, setPolicyDetails] = useState("");
  const [updatePolicyModal, setUpdatePolicyModal] = useState(false);
  const [userDetails, setUserDetails] = useState("");
  const [updateUserInfoModal, setUpdateUserInfoModal] = useState(false);
  const [policyDemoData, setPolicyDemoData] = useState([]);

  const fetchData = () => {
    fetch("/JsonData/newpolicy.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        setPolicyDemoData(result);
        setFilteredPolicy(result);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleOnClickAddPolicy = () => {
    setAddPolicy(true);
  };
  const handleOnClickAddUser = () => {
    setAddUser(true);
  };

  //Policy Pagination
  const PolicyPerPage = 5;
  const [currentPolicyPage, setCurrentPolicyPage] = useState(0);
  const PolicyStartIndex = currentPolicyPage * PolicyPerPage;
  const PolicyEndIndex = PolicyStartIndex + PolicyPerPage;
  const handlePolicyPageClick = (selectedPage) => {
    setCurrentPolicyPage(selectedPage.selected);
  };
  const [filteredPolicy, setFilteredPolicy] = useState([]);

  const currentPolicy = filteredPolicy.slice(PolicyStartIndex, PolicyEndIndex);
  //Users Pagination
  const CardsPerPage = 5;
  const [currentPage, setCurrentPage] = useState(0);
  const startIndex = currentPage * CardsPerPage;
  const endIndex = startIndex + CardsPerPage;
  const handlePageClick = (selectedPage) => {
    setCurrentPage(selectedPage.selected);
  };
  const [filteredCard, setFilteredCard] = useState(UsersDemoData);
  const navigate = useNavigate();
  const handleOnDelete = (PolicyName) => {
    //   console.log(Id);
    const index = policyDemoData.findIndex((e) => e.PolicyName === PolicyName);
    //  console.log(policyDemoData.filter((e) => e.id === Id ));

    navigate("/dashboard/access");
    policyDemoData.splice(index, 1);
  };
  const handleOnDeleteUser = (Id) => {
    const index = UsersDemoData.findIndex((e) => e.id === Id);

    navigate("/dashboard/access");
    UsersDemoData.splice(index, 1);
  };

  const currentCard = filteredCard.slice(startIndex, endIndex);

  const handleOnEdit = (PolicyDetails) => {
    setPolicyDetails(PolicyDetails);
    setUpdatePolicyModal(true);
  };

  const handleOnEditUser = (UserDetails) => {
    setUserDetails(UserDetails);
    setUpdateUserInfoModal(true);
  };

  return (
    <div className="bg-[#EBF3F9] w-[100%] h-[98%] ">
      <div className="curr-path flex flex-col p-5">
        <h3 className="font-semibold text-xl my-1">Access</h3>
        <ul className="flex items-center gap-x-1">
          <li className="text-[#454F5B] text-sm font-normal">Dashboard</li>
          <img
            className="h-3"
            src="/arrow_forward_ios.png"
            alt="/arrow-forward"
          />
          <li className="text-[#212B36] font-medium">Access</li>
        </ul>
      </div>

      <div className=" p-5 mb-10 bg-white w-[97%] ml-5 rounded-lg roboto">
        <div
          className=""
          style={{
            fontWeight: "500",
            fontSize: "32px",
          }}
        >
          Policy
        </div>
        <Tabs selectedTabClassName="Active">
          <TabList className="tablist">
            <Tab className="tab">Policy</Tab>
            <Tab className="tab">User Management</Tab>
          </TabList>

          <div className="tabs_underline"></div>
          <TabPanel>
            <div className=" mb-10">
              <div className=" bg-[#C3E5FF]  p-7 rounded-xl mb-10">
                <div
                  className="flex  items-center roboto"
                  style={{
                    fontSize: "20px",
                    fontWeight: "500",
                    color: "#212B36",
                  }}
                >
                  {" "}
                  <img
                    className=" mr-5 w-[30px] h-[30px]"
                    src="/info.png"
                    alt="info"
                  />{" "}
                  Want to allow multiple users to access an asset?
                </div>
                <div
                  className="roboto mt-2"
                  style={{
                    fontWeight: "400",
                    fontSize: "16px",
                    color: "#637381",
                  }}
                >
                  Create policy to manage users’ access to single or multiple
                  resources. Allows you to choose multiple user to be assigned
                  for the created policy.
                </div>
              </div>
              <div className="bottom">
                <div className=" flex justify-between">
                  <div
                    className="roboto"
                    style={{
                      fontSize: "24px",
                      fontWeight: "500",
                      marginBottom: "30px",
                      color: "#212B36",
                    }}
                  >
                    List of Policy
                  </div>
                  <div
                    className="bg-primaryBlue cursor-pointer text-white px-3 py-1 rounded-lg flex  h-[100%]"
                    onClick={handleOnClickAddPolicy}
                  >
                    <img
                      src="/Pluss.png"
                      alt="plus"
                      style={{ marginRight: "10px" }}
                    />
                    Add New Policy
                  </div>
                </div>
                <div className="border-[#CBD5E1] border rounded-lg">
                  <table className="w-[100%]">
                    <thead>
                      <tr>
                        <th
                          className="heading_row table-heading "
                          style={{
                            paddingLeft: "15px",
                            textAlign: "left",
                            paddingTop: "15px",
                            paddingTop: "15px",
                          }}
                        >
                          {" "}
                          Policy Name
                        </th>
                        <th
                          className="heading_row table-heading "
                          style={{
                            paddingLeft: "15px",
                            paddingTop: "15px",
                            paddingTop: "15px",
                          }}
                        >
                          {" "}
                          Created On
                        </th>
                        <th
                          className="heading_row table-heading "
                          style={{
                            paddingLeft: "15px",
                            paddingTop: "15px",
                            paddingTop: "15px",
                          }}
                        >
                          {" "}
                          Assigned Users
                        </th>
                        <th
                          className="heading_row table-heading "
                          style={{
                            paddingLeft: "15px",
                            paddingTop: "15px",
                            paddingTop: "15px",
                          }}
                        >
                          Allocated Assets
                        </th>
                        <th
                          className="heading_row table-heading "
                          style={{
                            paddingLeft: "15px",
                            paddingTop: "15px",
                            paddingTop: "15px",
                          }}
                        >
                          {" "}
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentPolicy.map((e, i) => {
                        return (
                          <tr key={i || e}>
                            <td className="item2">{e.PolicyName}</td>
                            <td className="item2">{e.CreatedOn}</td>
                            <td className="item2">{e.AssignedUsers}</td>
                            <td className="item2">{e.AllocatedAssest}</td>
                            <td className="item2 ">
                              <ActionButton
                                purpose={"Policy"}
                                handleOnEdit={handleOnEdit}
                                Details={e}
                                handleOnDelete={() =>
                                  handleOnDelete(e.PolicyName)
                                }
                              />
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  <div className="PaginationRow">
                    <ReactPaginate
                      breakLabel="..."
                      nextLabel=">"
                      onPageChange={handlePolicyPageClick}
                      pageRangeDisplayed={5}
                      pageCount={Math.ceil(
                        filteredPolicy.length / PolicyPerPage
                      )}
                      previousLabel="<"
                      containerClassName="pagination flex justify-center gap-4"
                      pageClassName="w-6 h-6 flex justify-center items-center"
                      pageLinkClassName="text-sm font-semibold "
                      activeClassName="bg-[#0078D7] rounded text-[#FFFFFF]"
                      previousLinkClassName="text-[#0078D7] font-bold"
                      nextLinkClassName="text-[#0078D7] font-bold"
                    />
                  </div>
                </div>
              </div>
            </div>
          </TabPanel>
          <TabPanel>
            <div className=" mb-11">
              <div className="bottom">
                <div className=" flex justify-between">
                  <div
                    className="roboto"
                    style={{
                      fontSize: "24px",
                      fontWeight: "500",
                      color: "#212B36",
                      marginBottom: "30px",
                    }}
                  >
                    List of Added Users
                  </div>
                  <div
                    className="bg-primaryBlue cursor-pointer text-white px-3 py-1 rounded-lg flex  h-[100%] text-md"
                    onClick={handleOnClickAddUser}
                  >
                    <img
                      src="/Pluss.png"
                      alt="plus"
                      style={{ marginRight: "10px" }}
                    />
                    Add New User
                  </div>
                </div>

                <div className="border-[#CBD5E1] border rounded-lg  ml-0">
                  <table className="w-[100%]">
                    <thead>
                      <tr>
                        <th
                          className="heading_row"
                          style={{
                            paddingLeft: "15px",
                            paddingTop: "15px",
                            paddingTop: "15px",
                          }}
                        >
                          {" "}
                          User Image
                        </th>
                        <th
                          className="heading_row"
                          style={{
                            paddingLeft: "15px",
                            paddingTop: "15px",
                            paddingTop: "15px",
                          }}
                        >
                          {" "}
                          User Name
                        </th>
                        <th
                          className="heading_row"
                          style={{
                            paddingLeft: "15px",
                            paddingTop: "15px",
                            paddingTop: "15px",
                          }}
                        >
                          {" "}
                          UserId
                        </th>
                        <th
                          className="heading_row"
                          style={{
                            paddingLeft: "15px",
                            paddingTop: "15px",
                            paddingTop: "15px",
                          }}
                        >
                          {" "}
                          Created On
                        </th>
                        <th
                          className="heading_row"
                          style={{
                            paddingLeft: "15px",
                            paddingTop: "15px",
                            paddingTop: "15px",
                          }}
                        >
                          {" "}
                          User Role
                        </th>
                        <th
                          className="heading_row"
                          style={{
                            paddingLeft: "15px",
                            paddingTop: "15px",
                            paddingTop: "15px",
                          }}
                        >
                          {" "}
                          Assigned Policy
                        </th>
                        <th
                          className="heading_row"
                          style={{
                            paddingLeft: "15px",
                            paddingTop: "15px",
                            paddingTop: "15px",
                          }}
                        >
                          {" "}
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentCard.map((e, i) => {
                        return (
                          <tr key={e.UserId || i}>
                            <td className="item table-data" style={{paddingLeft:"2rem"}}>
                              <img src={e.UserImage} alt="" />
                            </td>
                            <td className="item table-data">{e.UserName}</td>
                            <td className="item table-data">{e.UserId}</td>
                            <td className="item table-data">{e.CreatedOn}</td>
                            <td className="item table-data">{e.UserRole}</td>
                            <td className="item table-data">
                              {e.AssignedPolicy}
                            </td>
                            <td className="item table-data">
                              <ActionButton
                                purpose={"User"}
                                handleOnEdit={handleOnEditUser}
                                Details={e}
                                handleOnDelete={() => handleOnDeleteUser(e.id)}
                              />
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  <div className="PaginationRow2">
                    <ReactPaginate
                      breakLabel="..."
                      nextLabel=">"
                      onPageChange={handlePageClick}
                      pageRangeDisplayed={5}
                      pageCount={Math.ceil(filteredCard.length / CardsPerPage)}
                      previousLabel="<"
                      containerClassName="pagination flex justify-center gap-4"
                      pageClassName="w-6 h-6 flex justify-center items-center"
                      pageLinkClassName="text-sm font-semibold "
                      activeClassName="bg-[#0078D7] rounded text-[#FFFFFF]"
                      previousLinkClassName="text-[#0078D7] font-bold"
                      nextLinkClassName="text-[#0078D7] font-bold"
                    />
                  </div>
                </div>
              </div>
            </div>
          </TabPanel>
        </Tabs>

        {/* //Policy MODALS HERE */}
        {addPolicy && (
          <PolicyAdd
            addPolicy={addPolicy}
            setAddPolicy={setAddPolicy}
            policyDemoData={policyDemoData}
            UsersDemoData={UsersDemoData}
          />
        )}
        {updatePolicyModal && (
          <PolicyUpdate
            setUpdatePolicyModal={setUpdatePolicyModal}
            PolicyDetails={policyDetails}
            policyDemoData={policyDemoData}
            UsersDemoData={UsersDemoData}
          />
        )}
        {/* users modals */}
        {addUser && (
          <AddUserPage1
            setAddUserPage2={setAddUserPage2}
            setAddUser={setAddUser}
            AlluserDetails={UsersDemoData.filter((e) => e.UserRole)}
          />
        )}
        {addUserPage2 && (
          <AddUserPage2
            setAddUserPage2={setAddUserPage2}
            setAddUserPage3={setAddUserPage3}
            setAddedUserId={setAddedUserId}
          />
        )}
        {addUserPage3 && (
          <AddUserPage3
            setAddUserPage3={setAddUserPage3}
            addedUserId={addedUserId}
          />
        )}

        {updateUserInfoModal && (
          <UpdateUserInfo
            setUpdateUserInfoModal={setUpdateUserInfoModal}
            AlluserDetails={UsersDemoData.filter((e) => e.UserRole)}
            UserDetails={userDetails}
          />
        )}
      </div>
    </div>
  );
};

export default Accsess;