import React from "react";
import { Link } from "react-router-dom";

const Profile = () => {
  return (
    <main className="kumar w-[100%] p-5 bg-[#EBF3F9] ">
      <div className="card-custom">
        <div className="grid grid-cols-2 gap-4 ">
          <div className="">
            <h2 className="custom-heading-2">Edit Profile</h2>
          </div>
          <div className="">
            <Link to="/dashboard" className="closeimg">
              <img src="/close.png" alt="" />
            </Link>
          </div>
        </div>
        <div className="grid grid-cols-2 grid-rows-1 gap-4 mt-3 ">
          <div>
            <div className="profile-section-block">
              <div className="profile-section-img">
                <img src="/profile-img.png" alt="" />
              </div>
              <div className="profile-section-content">
                <h3>Ajay Kumar</h3>
                <small>CEO</small>
              </div>
            </div>
          </div>
          <div className="flex items-center justify-end">
            <a href="#" className="btn btn-blue d-inline-block mx-3">
              Upload New Photo
            </a>
            <a
              href="#"
              className="btn btn-white d-inline-block px-8 text-center"
              style={{ minWidth: "125px" }}
            >
              Delete
            </a>
          </div>
        </div>

        <div className="">
          <div className="flex flex-wrap mt-12 -ms-3 -me-3">
            <div className=" w-2/5  mb-9 bg-gray-500">
              <div className="px-3">
                <label
                  className="block custom-label uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="grid-first-name"
                >
                  First Name
                </label>
                <input
                  className="appearance-none form-control block w-full bg-gray-200 text-gray-700 border border-red-500 rounded-lg py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                  id="grid-first-name"
                  type="text"
                  placeholder="Jane"
                />
              </div>
            </div>
            <div className=" w-2/5  mb-9 bg-gray-500">
              <div className="px-3">
                <label
                  className="block custom-label uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="grid-first-name"
                >
                  Last name
                </label>
                <input
                  className="appearance-none form-control block w-full bg-gray-200 text-gray-700 border border-red-500 rounded-lg py-3 px-4  leading-tight focus:outline-none focus:bg-white"
                  id="grid-first-name"
                  type="text"
                  placeholder="Jane"
                />
              </div>
            </div>
            <div className="w-1/5 mb-9 bg-gray-400">
              <div className="px-3"></div>
            </div>
          </div>

          <div className="flex flex-wrap  -ms-3 -me-3">
            <div className=" w-2/5  mb-9 bg-gray-500">
              <div className="px-3">
                <label
                  className="block custom-label uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="grid-first-name"
                >
                  User name
                </label>
                <input
                  className="appearance-none form-control block w-full bg-gray-200 text-gray-700 border border-red-500 rounded-lg py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                  id="grid-first-name"
                  type="text"
                  placeholder="Jane"
                />
              </div>
            </div>
            <div className="w-2/5  mb-9 bg-gray-500">
              <div className="px-3">
                <label
                  className="block custom-label uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="grid-first-name"
                >
                  Designation
                </label>
                <input
                  className="appearance-none form-control block w-full bg-gray-200 text-gray-700 border border-red-500 rounded-lg py-3 px-4  leading-tight focus:outline-none focus:bg-white"
                  id="grid-first-name"
                  type="text"
                  placeholder="Jane"
                />
              </div>
            </div>
            <div className="w-1/5 mb-9 bg-gray-400">
              <div className="px-3"></div>
            </div>
          </div>

          <div className="flex flex-wrap  -ms-3 -me-3">
            <div className=" w-2/5  mb-9 bg-gray-500">
              <div className="px-3">
                <label
                  className="block custom-label uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="grid-first-name"
                >
                  Email Address
                </label>
                <input
                  className="appearance-none form-control block w-full bg-gray-200 text-gray-700 border border-red-500 rounded-lg py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                  id="grid-first-name"
                  type="text"
                  placeholder="Jane"
                />
              </div>
            </div>
            <div className=" w-2/5  mb-9 bg-gray-500">
              <div className="px-3">
                <label
                  className="block custom-label uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="grid-first-name"
                >
                  Phone Number
                </label>
                <input
                  className="appearance-none form-control block w-full bg-gray-200 text-gray-700 border border-red-500 rounded-lg py-3 px-4  leading-tight focus:outline-none focus:bg-white"
                  id="grid-first-name"
                  type="text"
                  placeholder="Jane"
                />
              </div>
            </div>
            <div className=" w-1/5 mb-9 bg-gray-400 self-end">
              <div className="px-3">
                <a href="#" className="btn block text-center btn-blue ">
                  Save Changes
                </a>
              </div>
            </div>
          </div>
          <hr
            className="border-0"
            style={{ background: "#C4CDD5", height: "0.8px" }}
          />
        </div>

        <div className="mt-7">
          <h2 className="custom-heading-2">Password change</h2>

          <div className="flex flex-wrap mt-5 -ms-3 -me-3">
            <div className=" w-5/12  mb-9 bg-gray-500">
              <div className="px-3">
                <label
                  className="block custom-label uppercase tracking-wide text-gray-700 text-xs font-bold mb-3"
                  htmlFor="grid-first-name"
                >
                  Current Password
                </label>
                <div className="inputicon">
                  <div className="keyimg">
                    <img src="/key-img.png" alt="" />
                  </div>
                  <input
                    className="appearance-none form-control block w-full bg-gray-200 text-gray-700 border border-red-500 rounded-lg py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                    id="grid-first-name"
                    type="text"
                    placeholder=""
                  />
                </div>
              </div>
            </div>
            <div className=" w-1/12 mb-9 bg-gray-400 self-end">
              <div className="px-3"></div>
            </div>
            <div className=" w-4/12  mb-9 bg-gray-500">
              <div className="px-3">
                <label
                  className="block custom-label uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="grid-first-name"
                >
                  New Password
                </label>
                <div className="inputicon">
                  <div className="keyimg">
                    <img src="/key-img.png" alt="" />
                  </div>
                  <div className="eyeimg">
                    <img src="/visibility.png" alt="" />
                  </div>
                  <input
                    className="appearance-none form-control block w-full bg-gray-200 text-gray-700 border border-red-500 rounded-lg py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                    id="grid-first-name"
                    type="text"
                    placeholder=""
                  />
                </div>
              </div>
            </div>
            <div className="w-1/12 mb-9 bg-gray-400 self-end">
              <div className="px-3"></div>
            </div>
          </div>

          <div className="flex flex-wrap mt-5 -ms-3 -me-3">
            <div className=" w-5/12  mb-9 bg-gray-500">
              <div className="px-3">
                <label
                  className="block custom-label uppercase tracking-wide text-gray-700 text-xs font-bold mb-3"
                  htmlFor="grid-first-name"
                >
                  Confirm New Password
                </label>
                <div className="inputicon">
                  <div className="keyimg">
                    <img src="/key-img.png" alt="" />
                  </div>
                  <input
                    className="appearance-none form-control block w-full bg-gray-200 text-gray-700 border border-red-500 rounded-lg py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                    id="grid-first-name"
                    type="text"
                    placeholder=""
                  />
                </div>
              </div>
            </div>
            <div className=" w-7/12  mb-9 bg-gray-500 flex justify-end">
              <div className="flex self-end justify-end">
                <a
                  href="#"
                  className="btn btn-white d-inline-block px-8 text-center"
                  style={{ minWidth: "125px" }}
                >
                  Cancel
                </a>
                <a href="#" className="btn btn-blue d-inline-block mx-3">
                  Save Changes
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Profile;