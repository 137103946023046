import React from 'react';

const Report = ({ setShowReport,ReportDetails }) => {
    const closeModal = ()=>{
        setShowReport(false);
    }
    // console.log(ReportDetails);
    return (
        <>
            <div className="inset-0 fixed bg-primaryGrey bg-opacity-25 flex justify-center items-center" style={{ zIndex: "100" }} >
                    <div className="bg-white rounded-lg w-96 p-6">
                        
                        <div className="flex justify-between items-center">
                            <h2 className="text-lg font-semibold">{}</h2>
                            <span className=" cursor-pointer text-gray-500 hover:text-gray-700" onClick={closeModal}>
                                <img className='h-[30px] w-[30px]' src="/close.png" alt="close-icon" />
                            </span>
                        </div>
                        <h1><span className=" text-lg font-semibold">Title:</span> {ReportDetails.Report?.map((e)=>{return(e.title)})}</h1>
                            <p><span className="text-lg font-semibold">Statement:</span> {ReportDetails.Report?.map((e)=>{return(e.report)})}</p>
                        <div className="flex justify-between ">
                            
                            <p className="text-gray-500 font-semibold ">Serial No.: {ReportDetails.SerialNumber}</p>
                        </div>
                        <div className="">
                           {<>
                            <p><span className="font-semibold">Charging Station Name: </span> {ReportDetails.StationName}</p>
                            <p><span className="font-semibold">Charging Station Address: </span>{ReportDetails.StationAddress}</p>
                           </>
                            }
                         
                            <p><span className="font-semibold">Generated at:</span> {ReportDetails.GeneratedOn}</p>
                        </div>
                    </div>
                </div>
          

        </>
    );
};

export default Report;
