import React from 'react'
import TicketStatusBtn from './TicketStatusBtn'
import { useState, useRef } from 'react'

const TicketStatusBtnDropDown = ({ backgroundColor, color, status, borderColor,TicketDetails }) => {

    const [open, setOpen] = useState(false)
    const menuRef = useRef()
    const clickRef = useRef()
    window.addEventListener('click', (e) => {
        if (e.target !== menuRef.current && e.target !== clickRef.current) {
            setOpen(false)
        }
    })
    const handleOnClickOpen = ()=>{
        TicketDetails.Status = "Open"
    }
    const handleOnClickClosed = ()=>{
        TicketDetails.Status = "Closed"
    }

    return (
        <div>

            <div className='relative inline-block text-left'>
                <div className="flex">
                    <TicketStatusBtn color={TicketDetails.Status === "Open" ? "#05FF00" : "#4A4A4A"}
                        backgroundColor={
                          TicketDetails.Status === "Open" ? "rgb(222 255 220)" : "#E8EEF3"
                        }
                        status={TicketDetails.Status}
                        borderColor={
                          TicketDetails.Status === "Open" ? "#35FF02" : "#CBD4C8"
                        } /><img ref={clickRef} onClick={()=>{setOpen(!open)}} className='px-3 h-2 cursor-pointer position-absolute' style={{position:"absolute",right:"0px",top:"13px"}} src="/expand_more.png" alt="expandmore-icon" />

                </div>
                {open && (
                    <div className='customdropdown  absolute right-0 z-10 mt-2 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1"' ref={menuRef}>
                        <div className="">

                            <div className="m-4 cursor-pointer" onClick={handleOnClickOpen}><TicketStatusBtn borderColor={"#35FF02"} backgroundColor={"rgb(222 255 220)"} color={"#05FF00"} status={"Open"} /></div>
                            <div className=" m-4 cursor-pointer" onClick={handleOnClickClosed}><TicketStatusBtn borderColor={"#CBD4C8"} backgroundColor={"#E8EEF3"} color={"#4A4A4A"} status={"Closed"} /></div>

                        </div>
                    </div>

                )}
            </div>
        </div>
    );
}

export default TicketStatusBtnDropDown
