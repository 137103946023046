import React from 'react'
import { Line } from 'react-chartjs-2';
import { Chart as chartjs, LineElement, CategoryScale, LinearScale, PointElement } from 'chart.js'
import AreaChart from './AreaChart';
import { useState } from 'react';
import accounting from 'accounting';

chartjs.register(LineElement, CategoryScale, LinearScale, PointElement)
const TotalRevenueCard = ({ propsData, DropDownPostion, backgroundColor }) => {

  const [total_revenue_generated,setTotal_Revenue_Generated] = useState(propsData[0].totalRevenueGenerated)
  const [total_transaction_completed,setTotal_Transaction_Completed] = useState(propsData[0].totalTransactionsCompleted);
  const [data, setData] = useState(propsData[0].point);
  const handleOnChange = (e) =>{
    if(e.target.value === "This months"){
        setData(propsData[0].point)
        setTotal_Transaction_Completed(propsData[0].totalTransactionsCompleted);
        setTotal_Revenue_Generated(propsData[0].totalRevenueGenerated);
    }
    else if (e.target.value === "Last month"){
        setData(propsData[1].point);
        setTotal_Transaction_Completed(propsData[1].totalTransactionsCompleted);
        setTotal_Revenue_Generated(propsData[1].totalRevenueGenerated);
    }
    else if (e.target.value === "Last 2 month"){
        setData(propsData[2].point);
        setTotal_Transaction_Completed(propsData[2].totalTransactionsCompleted);
        setTotal_Revenue_Generated(propsData[2].totalRevenueGenerated);
    }
    else if (e.target.value === "Last 6 month"){
        setData(propsData[3].point);
        setTotal_Transaction_Completed(propsData[3].totalTransactionsCompleted);
        setTotal_Revenue_Generated(propsData[3].totalRevenueGenerated);
    }
    else if (e.target.value === "Last 12 month"){
        setData(propsData[4].point);
        setTotal_Transaction_Completed(propsData[4].totalTransactionsCompleted);
        setTotal_Revenue_Generated(propsData[4].totalRevenueGenerated);
    }
    
}


  // const maindata = {
  //   labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
  //   datasets: [
  //     {
  //       label: 'Sample Data',
  //       data: propsData,
  //       fill: true,
  //       backgroundColor: 'transparent',
  //       borderColor: 'rgb(75, 192, 192)',
  //       pointBorderColor: 'transparent',
  //       pointBorderWidth: 4
  //     }
  //   ]
  // };
  // const options = {
  //   maintainAspectRatio:true,
  //   aspectRatio: 2.4,
  //   responsive: true,
  //   plugins: {
  //       legend:{
  //           display:false
  //         }
  //   },
  //   scales: {
  //       x: {
  //         grid: {
  //           display: false,
  //         },
  //       },
  //       y: {
  //           grid: {
  //               display: false,
  //             },

  //       }
  //     },
  // }




  return (
    <div>
      <div className="bg-white rounded-lg h-[340px] w-[100%]">
        <div className={`flex ${DropDownPostion}`}>

          <div className="options" >
            <select name="total_revenue" id="total-revenue" className='bg-[#DFE3E8] rounded-md p-1 outline-none ml-3' onChange={handleOnChange}>
              <option value="This months">This months</option>
              <option value="Last month">Last month</option>
              <option value="Last 2 month">Last 2 month</option>
              <option value="Last 6 month">Last 6 month</option>
              <option value="Last 12 month">Last 12 month</option>

            </select>
            
            {/* <div className='text-[0.7rem] mt-2 text-textGray'>
              From Sept 2023 to Jan 2024
            </div> */}
          </div>
        </div>
        <div className="flex">

          <div className="energyconsumtionDetails flex justify-center w-[100%] h-[205px]">
            <div className="EnergyConsumtionInfo flex  flex-col justify-center ">
              <div className="flex flex-col mb-5">
                <h1 className='flex justify-center  text-xl roboto'>Total Revenue Generated</h1>
                <span className='flex justify-center font-bold  roboto fs-32px' >₹{accounting.formatNumber(total_revenue_generated)}</span>
              </div>
              <div className="flex flex-col">
                <span className='flex justify-center text-xl roboto'>Total Transactions Completed</span>
                <span className='flex justify-center  font-bold roboto fs-32px'>{total_transaction_completed}</span>
              </div>
            </div>
            <div className="energyConsumtionChart_and_energyconsumtionDetails_separator"></div>
          </div>
          <div className="energyConsumtionChart ">
          <h2 className="" style={{
              marginLeft: "0px",
              fontWeight: "600",
              fontSize: "24px",
              marginTop:'-50px',
              marginBottom: '25px',
            }}>Revenue Generated</h2>
            <div className="w-[700px] ">
              <AreaChart aspectRatio={"3"} Filler={true} propsData={data} backgroundColor={backgroundColor} />

            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TotalRevenueCard
