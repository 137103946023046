import React, { useState } from "react";
import Loader from "../loadingSpinner"

const PolicyAdd = ({
  addPolicy,
  setAddPolicy,
  policyDemoData,
  UsersDemoData,
}) => {
  let ArrayOfUserRoles = [];
  let ArrayOfAllocatedAssets = [];
  let ArrayOfPolicyName = [];
  UsersDemoData.filter((e) => ArrayOfUserRoles.push(e.UserName));
  policyDemoData.filter((e) => ArrayOfAllocatedAssets.push(e.AllocatedAssest));
  policyDemoData.filter((e) => ArrayOfPolicyName.push(e.PolicyName));
  const [assignedUsers, setAssignedUsers] = useState([[], []]);
  const [allocatedAsset, setAllocatedAsset] = useState([[]]);
  const [policyName, setPolicyName] = useState("");
  let [loading, setLoading] = useState(false);
  const handleOnAddAsset = () => {
    const temp = [...allocatedAsset, []];
    setAllocatedAsset(temp);
  };
  const handleOnSelectAsset = (e, i) => {
    const inputData2 = [...allocatedAsset];
    inputData2[i] = e.target.value;
    setAllocatedAsset(inputData2);
  };
  const handleOnSelectUser = (e, i) => {
    const inputData = [...assignedUsers];
    inputData[i] = e.target.value;
    setAssignedUsers(inputData);
  };
  const handleOnAddUser = () => {
    const temp = [...assignedUsers, []];
    setAssignedUsers(temp);
  };
  const today = new Date();
  const date = today.getDate();
  const month = today.getMonth() + 1; // Months are zero-indexed, so we add 1
  const year = today.getFullYear();
  const CreatedOn = `${year}-${month}-${date}`;

  const newPolicy = {
    PolicyName: policyName,
    AssignedUsers: assignedUsers + ",",
    CreatedOn: CreatedOn,
    AllocatedAssest: allocatedAsset + ",",
    Action: "Activate",
  };
  const handleOnSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      policyDemoData.unshift(newPolicy);
      setAddPolicy(false);
    }, 2300);
    
  };
  return (
    <div>
      <Loader loading={loading}/>
      <div
        className="inset-0 fixed h-screen flex justify-center items-center bg-primaryGrey bg-opacity-25   "
        style={{ zIndex: "100" }}
      >
        <form  style={{
            height:"93vh"
          }} class="overflow-hidden">
          <div
            className="w-[600px]   bg-white rounded-xl h-full overflow-hidden"
            id="Modal"
          >
            <div
              className="flex justify-between items-center  p-5 h-[13%] "
              style={{ backgroundColor: "#EBF1F6" }}
            >
              <div
                className=""
                style={{
                  fontWeight: 600,
                  fontSize: "35px",
                }}
              >
                Add New Policy
              </div>
              <div className="h-full">
                <span
                  className="material-symbols-outlined cursor-pointer"
                  style={{
                    fontWeight: 600,
                    fontSize: "40px",
                  }}
                  onClick={() => {
                    setAddPolicy(false);
                  }}
                >
                  close
                </span>
              </div>
            </div>
            <div className="form px-10 py-3 flex flex-col overflow-y-scroll h-[570px]">
              <div
                className="Policy Name text-xl mb-3"
                style={{ fontWeight: "500" }}
              >
                Policy Name
              </div>
              <select
                name="Policy_name"
                id="Policy_name"
                className="w-[100%] p-3 text-lg mb-3"
                style={{ border: "1px solid #EBF1F6" }}
                onChange={(e) => setPolicyName(e.target.value)}
              >
                <option value="">Select your policy</option>
                {ArrayOfPolicyName.map((e) => {
                  return (
                    <option key={e} value={e}>
                      {e}
                    </option>
                  );
                })}
              </select>
              {assignedUsers.map((e, i) => {
                return (
                  <div key={i}>
                    <div
                      className=" text-xl mb-3"
                      style={{ fontWeight: "500" }}
                    >
                      Assigned User {i + 1}
                    </div>
                    <select
                      name="Assigned_user1"
                      id="Assigned_user1"
                      className="w-[100%] p-3 text-lg mb-3"
                      style={{ border: "1px solid #EBF1F6" }}
                      onChange={(e) => handleOnSelectUser(e, i)}
                    >
                      <option value="">Select your option</option>
                      {ArrayOfUserRoles.map((e) => {
                        return (
                          <option key={e} value={e}>
                            {e}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                );
              })}

              <div
                className="  px-3 py-1 flex items-center mb-3"
                style={{
                  width: "max-content",
                  color: "#0078D7",
                  fontWeight: "600",
                  border: "1px solid #0078D7",
                  borderRadius: "20px",
                  fontSize: "20px",
                }}
                onClick={handleOnAddUser}
              >
                <img
                  style={{
                    color: "#0078D7",
                    marginRight: "2px",
                    width: "20px",
                    height: "22px",
                  }}
                  src="/Blue_plus.png"
                  alt="plus"
                />{" "}
                Add User
              </div>
              {allocatedAsset.map((e, i) => {
                return (
                  <div key={i}>
                    <div
                      className=" text-xl mb-3"
                      style={{ fontWeight: "500" }}
                    >
                      Allocated Asset {i + 1}
                    </div>
                    <select
                      name="Assigned_user2"
                      id="Assigned_user2"
                      className="w-[100%] p-3 text-lg mb-3"
                      style={{ border: "1px solid #EBF1F6" }}
                      onChange={(e) => handleOnSelectAsset(e, i)}
                    >
                      <option value="">Select your option</option>
                      {ArrayOfAllocatedAssets.map((e) => {
                        return (
                          <option key={e} value={e}>
                            {e}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                );
              })}

              <div
                className="  px-3 py-1 flex items-center mb-3"
                style={{
                  width: "max-content",
                  color: "#0078D7",
                  fontWeight: "600",
                  border: "1px solid #0078D7",
                  borderRadius: "20px",
                  fontSize: "20px",
                }}
                onClick={handleOnAddAsset}
              >
                <img
                  style={{
                    color: "#0078D7",
                    marginRight: "2px",
                    width: "20px",
                    height: "22px",
                  }}
                  src="/Blue_plus.png"
                  alt="plus"
                />{" "}
                Add Asset
              </div>
              <div className="">
                <div className="flex justify-end items-center">
                  <div
                    className=" mr-5 cursor-pointer mb-4"
                    style={{
                      color: "#0078D7",
                      fontSize: "20px",
                      fontWeight: "600",
                    }}
                    onClick={() => {
                      setAddPolicy(false);
                    }}
                  >
                    Cancel
                  </div>
                  <div
                    className="  px-4 py-1 flex items-center cursor-pointer mb-3"
                    style={{
                      width: "max-content",
                      backgroundColor: "#0078D7",
                      color: "white",
                      fontWeight: "600",
                      borderRadius: "20px",
                      fontSize: "20px",
                    }}
                    onClick={handleOnSubmit}
                  >
                    Add Policy
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default PolicyAdd;
