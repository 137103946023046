import React from 'react'
import TicketStatusBtn from './TicketStatusBtn'
import { useState, useRef } from 'react'

const TicketPriorityBtnDropDown = ({ backgroundColor, color, status, borderColor, TicketDetails }) => {
    const [open, setOpen] = useState(false)
  const menuRef = useRef()
  const clickRef = useRef()
  window.addEventListener('click', (e) => {
    if (e.target !== menuRef.current && e.target !== clickRef.current) {
      setOpen(false)
    }
  })
  const handleOnClickHigh = () => {
    TicketDetails.Priority = "High"
  }
  const handleOnClickMedium = () => {
    TicketDetails.Priority = "Medium"
  }
  const handleOnClickLow = () => {
    TicketDetails.Priority = "Low"
  }

  return (
    <div>


      <div className='relative inline-block text-left'>
        <div className="flex">
          <TicketStatusBtn color={
            TicketDetails.Priority === "High"
              ? "#FF0000"
              : TicketDetails.Priority === "Medium"
                ? "#919333"
                : "#0085FF"
          }
            backgroundColor={
              TicketDetails.Priority === "High"
                ? "#ffcdcd"
                : TicketDetails.Priority === "Medium"
                  ? "rgb(255 249 194)"
                  : "rgb(221 234 255)"
            }
            status={TicketDetails.Priority}
            borderColor={
              TicketDetails.Priority === "High"
                ? "#FF0000"
                : TicketDetails.Priority === "Medium"
                  ? "#FFE602"
                  : "#0276FF"
            } /><img ref={clickRef} onClick={() => { setOpen(!open) }} className='px-3 h-2  cursor-pointer' style={{position:"absolute",right:"0px",top:"13px"}}src="/expand_more.png" alt="expandmore-icon" />

        </div>
        {open && (
          <div className='customdropdown  absolute right-0 z-10 mt-2 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1"' ref={menuRef}>
            <div className="">

              <div className=" m-4 cursor-pointer" onClick={handleOnClickHigh}><TicketStatusBtn borderColor={"#FF0000"} backgroundColor={"#ffcdcd"} color={"#FF0000"} status={"High"} /></div>
              <div className=" m-4 cursor-pointer" onClick={handleOnClickMedium}><TicketStatusBtn borderColor={"#FFE602"} backgroundColor={"rgb(255 249 194)"} color={"#919333"} status={"Medium"} /></div>
              <div className=" m-4 cursor-pointer" onClick={handleOnClickLow}><TicketStatusBtn borderColor={"#0276FF"} backgroundColor={"rgb(221 234 255)"} color={"#0085FF"} status={"Low"} /></div>
            </div>
          </div>

        )}
      </div>
    </div>
  );
}

export default TicketPriorityBtnDropDown
