import React from 'react'
import Charging_performance_modal from '../Modals/Charging_performance_modal';
import { useState } from 'react';

const ChargerCard = ({ ChargerHealth, ChargerSerialNumber, ChargerState,ModalData,width }) => {
   
    const [showModal, setShowModal] = useState(false);
    const handleOnViewCharger = () => {
        setShowModal(true);
    }
    return (
        <div className='border border-[#CBD4C8] rounded-lg w-[100%]  m-2 p-4 cursor-pointer ml-4npm start'>
            <div className='mb-6 flex justify-between items-center'>
                <h3 style={{ fontWeight: "600", fontSize: "20px" }}>Serial No: {ChargerSerialNumber}</h3>
                <div className="view-charger text-[0.75rem] px-2 font-Roboto rounded-md" style={ChargerHealth === "healthy" ? {
                    padding:'4px 10px',
                    borderRadius: "10px",
                    fontSize: "12px",
                    color:"white",
                    backgroundColor: '#0078D7'
                } : ChargerHealth === "damaged" ? {
                    padding:'4px 10px',
                    borderRadius: "10px",
                    fontSize: "12px",
                    color:"black",
                    backgroundColor: '#C4CDD5'
                } : { visibility: "hidden" }} onClick={handleOnViewCharger} >View Charger</div>
            </div>
            <div className='mb-6'>

            </div>
            <div className='flex justify-between'>
                <div className='flex flex-col'>
                    <div className='' style={{fontSize:"14px" ,color:"#637381"}}>Charger State</div>
                    <div className='text-[.7rem] font-medium px-2 py-1 text-center rounded-lg text-textGray'
                        style={ChargerState === "charging" ? { fontSize: "14px", border: "2px solid #00D77D ", weight: '600', color: "#00D77D", marginTop: '10px' } : ChargerState === "damaged" ? { fontSize: "14px", border: "2px solid #EF9E00 ", weight: '600', color: "#EF9E00", marginTop: '10px' } : { fontSize: "14px", border: "2px solid #F4CF0E", weight: '600', color: "#F4CF0E", marginTop: '10px' }} >{ChargerState}</div>
                </div>
                <div className='flex flex-col'>
                    <div className=''style={{fontSize:"14px" ,color:"#637381"}}>Charger Health</div>
                    <div className=' font-medium px-2 text-center rounded-lg text-primaryBlue py-1' style={ChargerHealth === "healthy" ? { fontSize: "14px", border: "2px solid #0078D7", weight: '600', color: "#0078D7", marginTop: '10px' } : ChargerState === "faulty" ? { fontSize: "14px", border: "2px solid #E40000 ", weight: '600', color: "#E40000", marginTop: '10px' } : { fontSize: "14px", border: "2px solid #E40000", weight: '600', color: "#E40000", marginTop: '10px' }}>{ChargerHealth}</div>
                </div>
            </div>
            {showModal && < Charging_performance_modal showModal={showModal} setShowModal={setShowModal} propsData={ModalData} ChargerSerialNumber={ChargerSerialNumber} ChargerHealth={ChargerHealth} ChargerState={ChargerState}/>}
        </div>
    )
}

export default ChargerCard
