import React from 'react'
import { useState, useRef } from 'react'
const Notifications = () => {

    const [open, setOpen] = useState(false)
    const menuRef = useRef()
    const clickRef = useRef()
    window.addEventListener('click', (e) => {
        if (e.target !== menuRef.current && e.target !== clickRef.current) {
            setOpen(false)
        }
    })

    return (
        <div>


            <div className='relative inline-block text-left'>
                <img ref={clickRef} onClick={() => { setOpen(!open) }} className='h-5 px-2 cursor-pointer ' src="/notifications.png" alt="actionBtn" />
                {open && (
                    <div className='customdropdown pt-[15px] pb-5 absolute right-0 z-10 mt-2  origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1"' ref={menuRef}>
                        <div className='w-[430px]  bg-[white] pe-9 '  style={{ borderBottomLeftRadius: "10px", paddingRight:'55px', borderBottomRightRadius: '10px' }}>
                            <div className="flex justify-between ps-5 mb-3">
                                <div className="font-semibold">Notification</div>
                                <div className=" font-semibold" style={{ color: "#0078D7" }}>Mark As Read</div>
                            </div>
                      
                            <div className="custom-sectionbordertop me-0 " style={{marginRight:'0px' , paddingTop:'10px',  paddingBottom:'8px',  paddingLeft:'20px'}}>
                                <div className='title-heading mb-1'>System Update Completed Successfully</div>
                                <div className='timelog'>5 minutes ago</div>
                            </div>
                            <div className="custom-sectionbordertop me-0 " style={{marginRight:'0px' , paddingTop:'10px',  paddingBottom:'8px',  paddingLeft:'20px'}}>
                                <div className='title-heading mb-1'>System Update Completed Successfully</div>
                                <div className='timelog'>5 minutes ago</div>
                            </div>
                            <div className="custom-sectionbordertop me-0 " style={{marginRight:'0px' , paddingTop:'10px',  paddingBottom:'8px',  paddingLeft:'20px'}}>
                                <div className='title-heading mb-1'>System Update Completed Successfully</div>
                                <div className='timelog'>5 minutes ago</div>
                            </div>
          
                        </div>

                    </div>

                )}
            </div>
        </div>
    );
}

export default Notifications
{/* <div className='w-[317px]  bg-[white] p-5 ' style={{borderBottomLeftRadius:"10px",borderBottomRightRadius:'10px'}}>
<div className="flex justify-between">
    <div className="font-semibold">Notification</div>
    <div className=" font-semibold" style={{color:"#0078D7"}}>Mark As Read</div>
</div>
<div className="NotificationSeparator"></div>
<div className="">
    <p className=''>System Update Completed Successfully</p>
    <p>5 minutes ago</p>
</div>
<div className="NotificationSeparator"></div>
<div className="">
    <p className=''>System Update Completed Successfully</p>
    <p>5 minutes ago</p>
</div>
<div className="NotificationSeparator"></div>
<div className="">
    <p className=''>System Update Completed Successfully</p>
    <p>5 minutes ago</p>
</div>
</div> */}