import {React,useEffect,useState} from 'react';
import { Link } from 'react-router-dom';
import DropDownProfile from './DropDownProfile';
import Notifications from './Notifications';


const CurrentTimeDisplay = () => {
    const [currentTime, setCurrentTime] = useState('');
  
    useEffect(() => {
      // Function to update the current time
      const updateCurrentTime = () => {
        const now = new Date();
  
        const hours = now.getHours().toString().padStart(2, '0');
        const minutes = now.getMinutes().toString().padStart(2, '0');
        const dayOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'][now.getDay()];
        const dayOfMonth = now.getDate();
        const month = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'][now.getMonth()];
        const year = now.getFullYear();
  
        const formattedTime = `${hours}:${minutes}, ${dayOfWeek}, ${dayOfMonth} ${month} ${year}`;
        setCurrentTime(formattedTime);
      };
  
      // Update the current time immediately and set up an interval to update it every minute
      updateCurrentTime();
      const intervalId = setInterval(updateCurrentTime, 60000);
  
      // Clean up the interval on component unmount
      return () => clearInterval(intervalId);
    }, []);
  
    return (
      <div>
        <p>{currentTime}</p>
      </div>
    );
  };

function Header() {
    return (
      <section id = "header-section" className='h-[5.8rem] relative z-[auto] '>
        <header className='  bg-white flex flex-col box-border' style={{height:'auto'}}>
            {/* top part */}
            <nav className="bottom-line p-3 flex justify-between items-center">
                <h1 className="heading text-3xl tracking-tight font-bold text-[#2E2E2E]">
                MYeKIGAI
                </h1>
                 {/* <Link to="/profile"> */}

                <div className="user">
                    <ul  className='flex items-center mx-2'>
                        <Notifications/>
                        <Link to="/profile">
                        <img className='h-8 px-3' src="/profile.png" alt="profile-icon" />
                        </Link>
                        <Link to="/profile">
                        <p className='text-secondaryGrey text-sm'>Ajay Kumar</p>
                        </Link>
                           <DropDownProfile/>
                    </ul>
                </div>
                                 
              
            </nav>
            {/* bottom part */}
            <div className="bottom ">
                <ul title ="current time" className='flex items-center h-4 p-4'>
                    <li className='text-secondaryGrey cursor-pointer'><CurrentTimeDisplay /></li>
                    {/* <img className='h-3 px-3' src={Arrow} alt="arrow-sign" /> */}
                    {/* <li className='text-primaryGrey font-medium'>Admin Dashboard</li> */}
                </ul>
            </div>
        </header>
        </section>
    );
}
export default Header;