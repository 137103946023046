import React from 'react'

const OfflineChargerCard = ({SerialNumber,OnlineTime}) => {
  return (
   
      <div className='border border-[#CBD4C8] rounded-lg w-[32%] h-[8rem] m-2 p-4 cursor-pointer'>
                                                <div className='mb-6'>
                                                    <h3 style={{ fontWeight: "600", fontSize: "20px" }}>Serial No: {SerialNumber}</h3>
                                                </div>
                                                <div className='' style={{fontSize:"15px"}}>
                                                    Last seen online at {OnlineTime}
                                                </div>
                                            </div>
    
  )
}

export default OfflineChargerCard
