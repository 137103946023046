import React, { useEffect, useState } from "react";

import TotalSessionCard from "../components/AdminDashboardComponents/TotalSessionCard";
import TotalRevenueCard from "../components/AdminDashboardComponents/TotalRevenueCard";
import EnergyConsumptionCard from "../components/AdminDashboardComponents/EnergyConsumptionCard";
import Card from "./Card";

import ReactPaginate from "react-paginate";
import TotalSessionCardDemoData from "../Demodata/TotalSessionCardData";
import TotalRevenueCardDemoData from "../Demodata/TotalRevenueCardData";

import { useNavigate } from "react-router-dom";
import DoughNutChart from "../components/AdminDashboardComponents/DoughNutChart";
import Report from "../components/Modals/Report";



function CmsAdminDashboard() {
  const [totalSessionCardDemoData, setTotalSessionCardDemoData] = useState([]);
  const [currentStation, setCurrentStation] = useState(null);
  const [stationData, setStationData] = useState([]);
  const [offlineChargerDetails, setOfflineChargerDetails] = useState([]);
  const [uniqueCustomer, setUniqueCustomer] = useState([]);
  const [availableStations, setAvailableStations] = useState(0);
  const [unavailableStations, setUnavailableStations] = useState(0);
  const [energyConsumptionDemoData, setEnergyConsumptionDemoData] = useState(
    []
  );
  const [showReport, setShowReport] = useState(false);
  const [reportDetails, setReportDetails] = useState(false);

  const fetchdata = async() => {
    fetch("/JsonData/energy.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        setEnergyConsumptionDemoData(result);
      });

    fetch("/JsonData/uniqueCustomer.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        setUniqueCustomer(result);
      });
    let offlineCharger = [];
    await fetch("/JsonData/stationsData1.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        setStationData(result);
        result.forEach((e) => {
          let totalSession = [
            {
              monday: 0,
              tuesday: 0,
              wednesday: 0,
              thrusday: 0,
              friday: 0,
              saturday: 0,
              sunday: 0,
            },
          ];

          e.chargers.map((e) =>
            e.sessions?.map((e) => {
              return (
                totalSession.map((p) => (p.monday += e.monday)),
                totalSession.map((p) => (p.tuesday += e.tuesday)),
                totalSession.map((p) => (p.wednesday += e.wednesday)),
                totalSession.map((p) => (p.thrusday += e.thrusday)),
                totalSession.map((p) => (p.friday += e.thrusday)),
                totalSession.map((p) => (p.friday += e.friday)),
                totalSession.map((p) => (p.saturday += e.saturday)),
                totalSession.map((p) => (p.sunday += e.sunday))
              );
            })
          );
          setTotalSessionCardDemoData(totalSession);
          e.chargers
            .filter((incharger) => {
              return incharger.chargerStatus === "offline";
            })
            .map((charger) => {
              return offlineCharger.push({
                stationName: e.name,
                stationAddress: e.address,
                charger: charger,
              });
            });
          // console.log(offlineCharger);
          setOfflineChargerDetails(offlineCharger);
        });
        setUnavailableStations(
          result.filter((e) => {
            return e.avalibility === false;
          }).length
        );
        setAvailableStations(
          result.filter((e) => {
            return e.avalibility === true;
          }).length
        );
      });
  };
  // console.log(offlineChargerDetails);
  useEffect(() => {
    fetchdata();
  }, []);
  // console.log(stationData);
  const CardsPerPage = 2;
  const [currentPage, setCurrentPage] = useState(0);
  const startIndex = currentPage * CardsPerPage;
  const endIndex = startIndex + CardsPerPage;
  const handlePageClick = (selectedPage) => {
    setCurrentPage(selectedPage.selected);
  };
  // const [filteredCard, setFilteredCard] = useState(offlineChargerDetails);
  const handleOnSelectFaultyChargerReportDropDown = (p) => {
    let offlineCharger = [];
    stationData.forEach((e) =>
      e.chargers
        .filter((incharger) => {
          return incharger.chargerStatus === "offline";
        })
        .map((charger) => {
          return offlineCharger.push({
            stationName: e.name,
            stationAddress: e.address,
            charger: charger,
          });
        })
    );

    const SelectCardByTime = offlineCharger.filter((e) =>
      e.charger.lastUpdated.includes(p.target.value)
    );
    setOfflineChargerDetails(SelectCardByTime);
  };

  // console.log(filteredCard);
  const currentCard = offlineChargerDetails.slice(startIndex, endIndex);
  // console.log(currentCard);

  // Delete Button Fuctionality in Card
  const navigate = useNavigate();
  const handleOnDelete = (id) => {
    const index = offlineChargerDetails.findIndex((e) => {
      return id === e.charger._id;
    });

    navigate("/dashboard");
    offlineChargerDetails.splice(index, 1);
  };

  return (
    <div className="admin-dashboard-container bg-[#EBF3F9] flex flex-col gap-4 responsive">
      {/* current location path */}
      <div className="curr-path flex flex-col">
        <h3 className="font-semibold text-xl my-1">Admin Dashboard</h3>
        <ul className="flex items-center gap-x-1">
          <li className="text-[#454F5B] text-sm font-normal">Dashboard</li>
          <img
            className="h-3"
            src="arrow_forward_ios.png"
            alt="arrow-forward"
          />
          <li className="text-[#212B36] font-medium">Admin Dashboard</li>
        </ul>
      </div>

      <div className="main-container">
        <div className="flex gap-4 ">
          <div className="left w-[50%]">
            <div className="flex gap-4 ">
              {/* revenue-card */}
              <div className="revenue-container bg-[#99EFCB] h-[12rem] w-[50%] flex flex-col items-center justify-center rounded-lg border-2 border-secondaryGreen">
                <h5 className="text-xl font-medium mb-2">
                  Total revenue generated
                </h5>
                <p className="text-[green] text-4xl font-semibold ">₹2,500,000</p>
              </div>
              {/* Energy Card */}
              <div className="revenue-container bg-[#C3E5FF] h-[12rem] w-[50%] flex flex-col items-center justify-center rounded-lg border-2 border-secondaryGreen">
                <h5 className="text-xl font-medium mb-2">
                  Total energy consumed
                </h5>
                <p className="text-[#0667F8] text-4xl font-semibold">100 kWh</p>
              </div>
            </div>
            {/* ChargingStationStatusCard */}
            <div className="mt-5">
              <DoughNutChart
                unavailableStations={unavailableStations}
                availableStations={availableStations}
              />
            </div>
            {/* TotalSessionCard */}
            <div className="mt-5 flex gap-5">
              {/* <TotalSessionCard/> */}
              <TotalSessionCard
                CardName={"Total Sessions"}
                barcolor={"#EF9E00"}
                propsData={TotalSessionCardDemoData}
              />
            </div>
          </div>
          {/* right side card */}
          <div className="right w-[50%] h-[100%] ">
            {/* top part */}
            <div className=" bg-white p-5 rounded-lg" style={{    height: '595px',}}>
              <div className="flex justify-between mb-5 ">
                <h3 className="font-semibold text-xl w-[300px]">
                  Recently Generated Reports for faulty Chargers
                </h3>
                <div className="options mb-6">
                  <select
                    name="total_revenue"
                    id="total-revenue"
                    className="bg-[#DFE3E8] rounded-md p-1 outline-none"
                    onChange={handleOnSelectFaultyChargerReportDropDown}
                  >
                    <option value="Last 24 hours">Last 24 hours</option>
                    <option value="Last 2 days">Last 2 days</option>
                    <option value="Last 5 days">Last 5 days</option>
                  </select>
                </div>
              </div>

              {/* bottom part */}
              <div className="bottom-part flex flex-col gap-4">
                {currentCard.length > 0 &&
                  currentCard.map((e) => {
                    return (
                      <Card
                        key={e.charger._id}
                        details={e.charger}
                        stationName={e.stationName}
                        stationAddress={e.stationAddress}
                        title={e.stationName}
                        handleOnDelete={handleOnDelete}
                        eleId={e.charger._id}
                        setShowReport={setShowReport}
                        setReportDetails={setReportDetails}
                      />
                    );
                  })}
                  
                <ReactPaginate
                  breakLabel="..."
                  nextLabel=">"
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={5}
                  pageCount={Math.ceil(
                    offlineChargerDetails.length / CardsPerPage
                  )}
                  previousLabel="<"
                  containerClassName="pagination flex justify-center gap-4"
                  pageClassName="w-6 h-6 flex justify-center items-center"
                  pageLinkClassName="text-sm font-semibold "
                  activeClassName="bg-[#0078D7] rounded text-[#FFFFFF]"
                  previousLinkClassName="text-[#0078D7] font-bold"
                  nextLinkClassName="text-[#0078D7] font-bold"
                />
              </div>
            </div>
            <div className="mt-6 flex gap-5">
              {/* {console.log(uniqueCustomer.length)} */}
              {uniqueCustomer.length > 0 && (
                <TotalSessionCard
                  CardName={"Unique Customers"}
                  barcolor="#00D77D"
                  propsData={uniqueCustomer}
                />
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="bottom">
        <TotalRevenueCard
          propsData={TotalRevenueCardDemoData}
          DropDownPostion={"ml-5 pt-3 pr-5 pb-3"}
          backgroundColor={"#B6EED7"}
        />
      </div>
      <div className="bottom2">
        {energyConsumptionDemoData.length > 0 && (
          <EnergyConsumptionCard
            aspectRatio={3.5}
            propsData={energyConsumptionDemoData}
          />
        )}
      </div>
     
      {showReport && (
        <Report ReportDetails={reportDetails} setShowReport={setShowReport} showReport={showReport}/>
      )}
    
    </div>
  );
}

export default CmsAdminDashboard;
