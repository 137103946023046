import { Bar } from "react-chartjs-2";
import { BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip } from "chart.js";
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);





export default function BarCharts({barcolor,propsData,aspectRatio,height}) {
    //  console.log(propsData.map(e=>e.monday));
    let labels =[];let NumbericData = [];
    for( let key in propsData){
         labels.push(key);
         NumbericData.push(propsData[key])
        };
       
    const option = {
        maintainAspectRatio:false,
        aspectRatio:  2,
        responsive: true,
        plugins: {
            legend:{
                display:false
              }
        },
        scales: {
            x: {
              grid: {
                display: false,
              },
              ticks:{
                font:{
                  size:'10px',
                  weight:"bold",
                },
              },
            },
            y: {
                grid: {
                    display: false,
                  },
                  ticks:{
                    font:{
                      size:'10px',
                      weight:"bold",
                    },
                  },
                  
            }
          },
    };
    const data = {
        labels:labels,
        datasets: [
            {
                label: "",
                data: NumbericData,
                backgroundColor: barcolor,
                borderRadius:0
            }
        ],
    
    
    };
    return (
        <div className="App " style={{height:height?height:"300px"}}>
            <Bar options={option} data={data} />
        </div>
    );
}