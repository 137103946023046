import * as React from 'react';
import { useState } from 'react';



export default function PeopleAsk({question,answer}) {
  const [openAccordion,setOpenAccordion]=useState(false);
  // const [height,setHeight]= useState("85px")
  const handleOnClick = ()=>{
    setOpenAccordion(!openAccordion);
   


  }
  return (
    <div>

      <div className={" justify-center overflow-hidden"} style={{transition:"height 0.5s ease-in-out", height:"auto"}}>
        <div className="flex  justify-between" style={{
          fontSize: "18px",
          paddingBottom:"20px",
          position:'relative',
          paddingLeft:"80px",
          paddingTop: "20px",
          paddingRight: "110px",
          fontWeight: "600",
          
        }}>{question}
        <div style={{position:"absolute", right:"20px", marginTop:'-3px', top:'50%' }} onClick={handleOnClick}>
          {openAccordion?(<img style={{width:"12px", cursor:'pointer'}} src="/arrowup.png" alt="arrow" />):(<img style={{width:"12px", cursor:'pointer'}} src="/arrowdown.png" alt="arrow" />)}
        </div>
        </div>
       {openAccordion &&  <div className="" style={{
          fontSize: "18px",
          paddingBottom:"25px",
          paddingLeft:"80px",
          paddingTop: "25px",
          fontWeight: "600",
        
          paddingRight:'80px',
      
        }}>{answer}</div>}
      </div>
     

     
    </div>
  );
}



