const IndianUsersDemoData = [
  {
    "id": 21,
    "UserImage": "/avatar.jpg",
    "UserName": "Rohan",
    "UserId": "Rohan@123",
    "CreatedOn": "Feb 12 2023",
    "UserRole": "Software Engineer",
    "AssignedPolicy": "Mumbai Chargers",
    "ACTION": "...",
    "Email": "rohan.kumar@example.com",
    "LastName": "Kumar",
    "MobileNumber": "+91 9876543210",
  },
  {
    "id": 22,
    "UserImage": "/avatar.jpg",
    "UserName": "Priya",
    "UserId": "Priya@456",
    "CreatedOn": "Mar 05 2022",
    "UserRole": "Data Analyst",
    "AssignedPolicy": "Delhi Stations",
    "ACTION": "...",
    "Email": "priya.singh@example.com",
    "LastName": "Singh",
    "MobileNumber": "+91 8765432109",
  },
  {
    "id": 23,
    "UserImage": "/avatar.jpg",
    "UserName": "Ankit",
    "UserId": "Ankit@789",
    "CreatedOn": "Apr 18 2023",
    "UserRole": "System Administrator",
    "AssignedPolicy": "Bangalore Batteries",
    "ACTION": "...",
    "Email": "ankit.sharma@example.com",
    "LastName": "Sharma",
    "MobileNumber": "+91 7654321098",
  },
  {
    "id": 24,
    "UserImage": "/avatar.jpg",
    "UserName": "Neha",
    "UserId": "Neha@012",
    "CreatedOn": "Jun 30 2022",
    "UserRole": "UI/UX Designer",
    "AssignedPolicy": "Hyderabad Chargers",
    "ACTION": "...",
    "Email": "neha.verma@example.com",
    "LastName": "Verma",
    "MobileNumber": "+91 6543210987",
  },
  {
    "id": 25,
    "UserImage": "/avatar.jpg",
    "UserName": "Amit",
    "UserId": "Amit@345",
    "CreatedOn": "Aug 24 2023",
    "UserRole": "Network Engineer",
    "AssignedPolicy": "Chennai Power Stations",
    "ACTION": "...",
    "Email": "amit.yadav@example.com",
    "LastName": "Yadav",
    "MobileNumber": "+91 5432109876",
  },
  {
    "id": 26,
    "UserImage": "/avatar.jpg",
    "UserName": "Deepika",
    "UserId": "Deepika@678",
    "CreatedOn": "Sep 15 2022",
    "UserRole": "Database Administrator",
    "AssignedPolicy": "Pune Batteries",
    "ACTION": "...",
    "Email": "deepika.gupta@example.com",
    "LastName": "Gupta",
    "MobileNumber": "+91 4321098765",
  },
  {
    "id": 27,
    "UserImage": "/avatar.jpg",
    "UserName": "Raj",
    "UserId": "Raj@901",
    "CreatedOn": "Oct 29 2023",
    "UserRole": "DevOps Engineer",
    "AssignedPolicy": "Kolkata Charging Stations",
    "ACTION": "...",
    "Email": "raj.sharma@example.com",
    "LastName": "Sharma",
    "MobileNumber": "+91 3210987654",
  },
  {
    "id": 28,
    "UserImage": "/avatar.jpg",
    "UserName": "Sneha",
    "UserId": "Sneha@234",
    "CreatedOn": "Nov 14 2022",
    "UserRole": "Business Analyst",
    "AssignedPolicy": "Delhi Batteries",
    "ACTION": "...",
    "Email": "sneha.malhotra@example.com",
    "LastName": "Malhotra",
    "MobileNumber": "+91 2109876543",
  },
  {
    "id": 29,
    "UserImage": "/avatar.jpg",
    "UserName": "Vikas",
    "UserId": "Vikas@567",
    "CreatedOn": "Dec 03 2023",
    "UserRole": "Data Scientist",
    "AssignedPolicy": "Mumbai Power Stations",
    "ACTION": "...",
    "Email": "vikas.verma@example.com",
    "LastName": "Verma",
    "MobileNumber": "+91 1098765432",
  },
  {
    "id": 30,
    "UserImage": "/avatar.jpg",
    "UserName": "Aishwarya",
    "UserId": "Aishwarya@890",
    "CreatedOn": "Jan 20 2022",
    "UserRole": "Technical Writer",
    "AssignedPolicy": "Bangalore Chargers",
    "ACTION": "...",
    "Email": "aishwarya.kulkarni@example.com",
    "LastName": "Kulkarni",
    "MobileNumber": "+91 9876543210",
  },
];

export default IndianUsersDemoData;
