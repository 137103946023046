import { useState, useEffect } from "react";
import DashboardNavigator from "../components/DashboardNavigator";
import MainContainer from "../components/MainContainer";
import StationCard from "../components/StationCard";
import stationData from "../Demodata/stationsdata.json";
import ReactPaginate from "react-paginate";
import {
  GoogleMap,
  MarkerF,
  useJsApiLoader,
  InfoWindowF,
} from "@react-google-maps/api";
import markerImg from "../assets/marker.png";

const StationsPerPage = 3;

function ChargingStations() {
  const mapContainerStyle = {
    borderRadius: "0px 10px 10px 0",
    width: "100%",
    height: "650px",
  };

  const { isLoaded, loadError } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyDUEAx31VYNSXLljZP9wElHctV5W3sEdvg",
  });
  const [currentPage, setCurrentPage] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [filteredStations, setFilteredStations] = useState([]);
  const [selectedStation, setSelectedStation] = useState(null);
  const [mapCenter, setMapCenter] = useState({
    lat: stationData[0].latitude,
    lng: stationData[0].longitude,
  });

  useEffect(() => {
    // Filter stations based on the address when searchText changes
    const filtered = stationData.filter((station) => {
      return (
        station.address.toLowerCase().includes(searchText.toLowerCase()) ||
        station.name.toLowerCase().includes(searchText.toLowerCase())
      );
    });
    setFilteredStations(filtered);
    setCurrentPage(0); // Reset to the first page when search text changes
  }, [searchText]);

  // Calculate the start and end indices for the current page
  const startIndex = currentPage * StationsPerPage;
  const endIndex = startIndex + StationsPerPage;

  // Get the stations for the current page
  const currentStations = filteredStations.slice(startIndex, endIndex);

  const handlePageClick = (selectedPage) => {
    setCurrentPage(selectedPage.selected);
  };

  const searchStation = (e) => {
    setSearchText(e.target.value);
  };
  const handleMarkerClick = (station) => {
    setSelectedStation(station);
  };

  const handleInfoWindowClose = () => {
    setSelectedStation(null);
  };
  const handleOnFilter = (appliedFilter) => {
    if (appliedFilter.target.value === "Available") {
      const filteringData = stationData.filter((e) => {
        return e.avalibility === true;
      });
      setFilteredStations(filteringData);
    } else if (appliedFilter.target.value === "Filter") {
      const filtered = stationData.filter((station) => {
        return (
          station.address.toLowerCase().includes(searchText.toLowerCase()) ||
          station.name.toLowerCase().includes(searchText.toLowerCase())
        );
      });
      setFilteredStations(filtered);
    } else if (appliedFilter.target.value === "HighestRating") {
      const tempStationData = stationData;
      const highestRatedstation = tempStationData.sort(
        (a, b) => b.rate - a.rate
      );
      setFilteredStations(highestRatedstation);
    }
  };

  return (
    <main className="kumar w-[100%] p-5 bg-[#EBF3F9] ">
      <DashboardNavigator pageName={"Charging Stations"} />
      <MainContainer>
        <section id="stations-section" className="flex ">
          {/* stations details  */}
          <div id="stations-div" className="gap-3">
            <h2>List of charging stations</h2>
            <div
              id="search-filter"
              className="flex flex-nowrap justify-between"
            >
              <div id="search-stations">
                <span className="material-symbols-outlined">search</span>
                <input
                  type="search"
                  className="customInput"
                  onChange={searchStation}
                  placeholder="Search for Station"
                />
              </div>
              {/* <button id="filter-btn" className="w-[150px]"> */}

              <select
                name="total_revenue"
                id="total-revenue"
                className="bg-[#F6F6F6] rounded-md p-1 outline-none ml-[10px]"
                style={{ border: "1px solid var(--Grey-300, #DFE3E8)" }}
                onChange={handleOnFilter}
              >
                <option value="Filter">Filter</option>
                <option value="Available">Available</option>
                <option value="HighestRating">Highest Rating</option>
              </select>
              {/* <span className="material-symbols-outlined"><div className="options mb-6">
                  </div></span> */}
              {/* </button> */}
            </div>
            <ul id="station-ul">
              
              {currentStations.map((station, index) => (
                <li key={index}>
                  {/* Pass the station data to the StationCard component */}
                  <StationCard
                    data={station}
                    changeMapCenter={setMapCenter}
                    changeSelectedStation={setSelectedStation}
                    totalReviews = {station.reviews.length}  />
                </li>
              ))}
            </ul>
            <ReactPaginate
              breakLabel="..."
              nextLabel=">"
              onPageChange={handlePageClick}
              pageRangeDisplayed={5}
              pageCount={Math.ceil(filteredStations.length / StationsPerPage)}
              previousLabel="<"
              containerClassName="pagination flex justify-center gap-4"
              pageClassName="w-6 h-6 flex justify-center items-center"
              pageLinkClassName="text-sm font-semibold "
              activeClassName="bg-[#0078D7] rounded text-[#FFFFFF]"
              previousLinkClassName="text-[#0078D7] font-bold"
              nextLinkClassName="text-[#0078D7] font-bold"
            />
          </div>
          {/* this is for map */}
          <div id="chargers-map">
            {loadError ? (
              <>
                <h1>Error while loading maps</h1>
              </>
            ) : !isLoaded ? (
              <>
                <h1>Loading map ..</h1>
              </>
            ) : (
              <>
                <div>
                  <GoogleMap
                    mapContainerStyle={mapContainerStyle}
                    zoom={12}
                    center={mapCenter}
                  >
                    {stationData.map((data, index) => {
                      return (
                        <MarkerF
                          key={index}
                          position={{ lat: data.latitude, lng: data.longitude }}
                          icon={markerImg}
                          onClick={() => handleMarkerClick(data)}
                        ></MarkerF>
                      );
                    })}
                    {/* InfoWindow for selected station */}
                    {selectedStation && (
                      <InfoWindowF
                        position={{
                          lat: selectedStation.latitude,
                          lng: selectedStation.longitude,
                        }}
                        onCloseClick={handleInfoWindowClose}
                      >
                        <div>
                          <h2>{selectedStation.name}</h2>
                          <p>{selectedStation.address}</p>
                        </div>
                      </InfoWindowF>
                    )}
                  </GoogleMap>
                </div>
              </>
            )}
          </div>
        </section>
      </MainContainer>
    </main>
  );
}

export default ChargingStations;
