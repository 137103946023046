import React from 'react'

const StationRating = () => {
  const TotalRating = 10000;
  const fiveStarRating = 4000;
  const fourStarRating = 2000;
  const threeStarRating = 3213;
  const twoStarRating = 1000;
  const oneStarRating = 987;
  const AverageRating = Math.trunc((fiveStarRating+fourStarRating+threeStarRating+twoStarRating+oneStarRating)/5);
  return (
    <div className='w-[300px]'>
      <div className="" style={{width:"fit-content"}}>Station Ratings
      </div>
      <div className="mb-4" style={{ fontSize: "20px", color: "black", width:"fit-content" }}>
         <span className='text-[#F4CF0E] text-[32px]'>&#9733;</span> <span className='text-[30px]'>4.8</span>/5  <span className='text-[20px] font-normal'>({TotalRating} ratings)</span>
      </div>

      <div className="flex justify-start items-center" >
        <div className="text-[black] text-[18px]" >5 &#9733;</div>
        <div className="ratingbar">
          <div className="bar" style={{ width: `${((fiveStarRating / TotalRating) * 100)}%` }}>
            <div className="ratingOnBar"></div>
          </div>
        </div>
        <div className="">
          <div className="text-[black] text-[18px]">{`${Math.trunc(((fiveStarRating / TotalRating) * 100))}%`}</div>
        </div>
      </div>

      <div className="flex justify-start items-center">
        <div className="text-[black] text-[18px]">4 &#9733;</div>
        <div className="ratingbar w-[200px]">
          <div className="bar" style={{ width: `${((fourStarRating / TotalRating) * 100)}%` }}>
            <div className="ratingOnBar"></div>
          </div>
        </div>
        <div className="">

          <div className="text-[black] text-[18px]">{`${Math.trunc(((fourStarRating / TotalRating) * 100))}%`}</div>
        </div>
      </div>

      <div className="flex justify-start items-center">
        <div className="text-[black] text-[18px]">3 &#9733;</div>
        <div className="ratingbar w-[200px]">
          <div className="bar" style={{ width: `${((threeStarRating / TotalRating) * 100)}%` }}>
            <div className="ratingOnBar"></div>
          </div>
        </div>
        <div className="">

          <div className="text-[black] text-[18px]">{`${Math.trunc(((threeStarRating / TotalRating) * 100))}%`}</div>
        </div>
      </div>

      <div className="flex justify-start items-center">
        <div className="text-[black] text-[18px]">2 &#9733;</div>
        <div className="ratingbar w-[200px]">
          <div className="bar" style={{ width: `${((twoStarRating / TotalRating) * 100)}%` }}>
            <div className="ratingOnBar"></div>
          </div>
        </div>
        <div className="">

          <div className="text-[black] text-[18px]">{`${Math.trunc(((twoStarRating / TotalRating) * 100))}%`}</div>
        </div>
      </div>

      <div className="flex justify-start items-center" >
        <div className="text-[black] text-[18px]">1 &#9733;</div>
        <div className="ratingbar w-[200px]">
          <div className="bar" style={{ width: `${((oneStarRating / TotalRating) * 100)}%` }}>
            <div className="ratingOnBar"></div>
          </div>
        </div>
        <div className="">

          <div className="text-[black] text-[18px]">{`${Math.trunc(((oneStarRating / TotalRating) * 100))}%`}</div>
        </div>
      </div>
    </div>
  )
}

export default StationRating