import React from 'react'

const UserReviewCard = ({UserName,rating,input,date}) => {
  
  return (
    <div className='w-[330px] h-[164px] mt-5 rounded-xl p-3' style={{backgroundColor:'#F8F9FA'}}>
      <div className="head flex items-center ">
        <div className="img" ><img className='rounded-full' src="/avatar.jpg" alt="img" style={{width:'45px',margin:"5px"}} /></div>
        <div className="">
        <div className="Username" style={{fontSize:"15px", fontWeight:'500', color:"#000"}}>{UserName}</div>
        <div className="Ratings"style={{fontSize:"15px", fontWeight:'600'}}>{rating}<span style ={{color:"gold"}}>&#9733;</span></div>
        </div>
      </div>
      <div className="middle  pl-5 pr-5 " style={{color:'#000'}}>
        <div className="description" style={{fontSize:"10px"}}>{input}</div>
      </div>
      <div className="bottom">
        <div className="flex justify-end">
            <div className=""style={{color:'#000',fontSize:"10px"}} >{date}</div>
        </div>
      </div>
    </div>
  )
}

export default UserReviewCard
