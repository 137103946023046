import React from 'react'

import { useState, useEffect } from 'react';
import Test from '../components/Help/PeopleAsk'

// const demos = [
//    {
//     "id": 1,
//     "question": "What is a Charging Management System (CMS)?",
//     "answer": "A Charging Management System (CMS) is a comprehensive platform designed to efficiently manage and optimize the charging infrastructure for electric vehicles (EVs). It encompasses various features to monitor, control, and analyze the charging process, ensuring a seamless and efficient experience for both operators and end-users."
//   },
//   {
//     "id": 2,
//     "question": "What role does a Charging Management System play for a Charging Point Operator?",
//     "answer": "Charging Management System is a crucial tool for a Charging Point Operator as it helps them by providing real-time monitoring, remote control capabilities, predictive maintenance features, analytics and control of their charging stations. It ensures optimal usage of charging stations, minimizes downtime andt also facilitates features such as real-time status updates, billing, user authentication, and remote troubleshooting, ensuring seamless operation of the charging network."
//   },
//   {
//     "id": 3,
//     "question": "How does the Charging Management System ensure user authentication and payment processing?",
//     "answer": "Charging Management System typically integrates user authentication methods such as RFID cards, mobile apps, or account credentials. It also includes secure payment processing functionalities, allowing users to pay for their charging sessions through various methods, such as credit cards, mobile payments, or subscription plans."
//   },
//   {
//     "id": 4,
//     "question": "What are the key benefits of using a Charging Management System for a Charging Point Operator?",
//     "answer": "Some key benefits include:\n\nRemote Monitoring: Enables real-time monitoring of charging stations for status updates and issue detection.\nBilling and Payments: Automates billing processes and provides secure payment options for users.\nUser Management: Facilitates user authentication, access control, and account management.\nData Analytics: Offers insights into usage patterns, energy consumption, and station performance for informed decision-making.\nFault Detection and Maintenance: Identifies and alerts operators about faults or maintenance needs, streamlining upkeep."
//   },
//   {
//     "id": 5,
//     "question": "How does the Charging Management System handle interoperability with different charging station models and hardware?",
//     "answer": ""
//   }
//   ];

const Help = () => {
    const [searchText, setSearchText] = useState('');
    const [totalQNA,setTotalQNA] = useState([]);
    const [demoQA, setDemoQA] = useState({});

    //    const iterateData =(demoQA)=>{
    //          for(let key in demoQA){
    //             if(typeof(demoQA[key])==="object" && demoQA[key]!==null){
    //                 iterateData(demoQA[key]);
    //             }
    //             else{

    //             }
    //          }
    //    }
    //   iterateData(demoQA);


    function extractQnA(data) {
        const qnaArray = [];

        // Recursive function to traverse the JSON data
        function traverse(data) {
            // Check if the data is an object
            if (typeof data === 'object' && data !== null) {
                // If it's an array, iterate over its elements
                if (Array.isArray(data)) {
                    data.forEach(element => {
                        traverse(element); // Recursively traverse each element
                    });
                } else {
                    // If it's an object, iterate over its keys
                    Object.keys(data).forEach(key => {
                        const value = data[key];
                        // Check if the key is 'question' and there's a corresponding 'answer' key
                        if (key === 'question' && data.hasOwnProperty('answer')) {
                            // Add the question-answer pair to the qnaArray
                            qnaArray.push({ question: data.question, answer: data.answer });
                        }
                        // Recursively traverse the value
                        traverse(value);
                    });
                }
            }
            // No need to handle non-object or non-array values here
            // They won't contain Q&A pairs
            // You can add handling if needed
        }

        // Start traversing the JSON data
        traverse(data);

        return qnaArray;
    }

    
    
    
    
    const fetchData = () => {
        fetch("/JsonData/q&a.json", {
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        })
        .then((response) => {
            return response.json();
        })
        .then((result) => {
            setDemoQA(result);
            setTotalQNA(extractQnA(result));
            });
    }
    
   

  const handleOnChangeSearchText = (p)=>{
    setSearchText(p.target.value)
    const filtered = totalQNA.filter((e) => { return (e.question.toLowerCase().includes(p.target.value.toLowerCase()) || e.answer.toLowerCase().includes(p.target.value.toLowerCase())) }
    );
    setTotalQNA(filtered)
    console.log(totalQNA);
    if(filtered.length===0 || p.target.value===""){
        fetchData()
    }
    
  }
    
    
    
    
    useEffect(() => {
        
        fetchData();
    }, [])
    return (
        <>
            <div className='bg-[#EBF3F9] w-[100%] h-[100%] '>
                <div className="curr-path flex flex-col p-5 " >
                    <h3 className="font-semibold text-xl my-1">Ticket Status</h3>
                    <ul className="flex items-center gap-x-1">
                        <li className="text-[#454F5B] text-sm font-normal">Dashboard</li>
                        <img
                            className="h-3"
                            src="/arrow_forward_ios.png"
                            alt="/arrow-forward"
                        />
                        <li className="text-[#212B36] font-medium">Ticket Status</li>
                    </ul>
                </div>
                <div className="bg-[#FDFDFD] rounded-3xl w-[97%]   ml-5 mb-8 ">
                    <div className="flex flex-col p-10 h-[20%] justify-center items-center ">
                        <div className="" style={{
                            marginBottom: "20px",
                            color: "#0078D7",
                            fontWeight: "600",
                            fontSize: "50px"
                        }}>How can we help you?</div>
                        <div className="">
                            <div id="search-stations" style={{
                                padding: "0", boxShadow: "1px 1px 1px 1px lightgray",
                                padding: "0px",
                                width: "800px",
                                height: "60px",
                                backgroundColor: "white",

                            }}   >
                                <span className="material-symbols-outlined ml-10 mr-10 bg-white" style={{ fontSize: "35px" }}>search</span>
                                <input type="search" placeholder="Describe your issue" className='w-[100%] ml-3 customInput' style={{ backgroundColor: "white", fontSize: "20px" }} value={searchText} onChange={ handleOnChangeSearchText } />
                            </div>
                        </div>
                    </div>
                    <div className="">
                        <div className="" style={{
                            fontSize: "35px",
                            marginLeft: "76px",
                            fontWeight: "600"
                        }}>
                            Browse help topics
                        </div>


                        <div className="w-[100%]  p-10 mt-10 flex flex-col items-center  " style={{padding:'0px 75px'}}>
<div style={{border: "1px solid #CBD5E1"}} className='rounded-lg mb-10'>
                            {totalQNA.map((e, i) => {
                                return (
                                    <div className="  " style={{

                                        width: "100%",
                                        borderBottom: "1px solid #CBD5E1",
                                        borderTop: "1px solid #CBD5E1",
                                        borderRight: "1px solid #CBD5E1",
                                        borderLeft: "1px solid #CBD5E1",
                                    }} key={e.question}>
                                        <Test question={e.question} answer={e.answer} />
                                    </div>
                                )
                            })}
                        </div>
                        </div>
                    </div>
                </div>

            </div >
        </>
    )
}

export default Help
