import React from 'react'
import UserReviewCard from '../UserAnalytics/UserReviewCard';
import { useState } from 'react';
import ReactPaginate from 'react-paginate';


const AllReviewsModal = ({ setShowAllReviewsModal, currentStation }) => {
    const CardsPerPage = 6;
    const [currentPage, setCurrentPage] = useState(0);

    const startIndex = currentPage * CardsPerPage;
    const endIndex = startIndex + CardsPerPage;
    const handlePageClick = (selectedPage) => {
        setCurrentPage(selectedPage.selected);
    };
    const [filteredCard, setFilteredCard] = useState(currentStation.reviews);
    const currentCard = filteredCard.slice(startIndex, endIndex);
    const handleOnClose = () => {
        setShowAllReviewsModal(false);
    }
   
    return (
        <div className="inset-0 fixed  bg-primaryGrey bg-opacity-25 flex justify-center items-center" style={{ zIndex: "100" }}>
            <div className='w-[1200px] h-[470px] rounded-xl overflow-auto  bg-white' id='Modal'>
                {/* Modal Header */}
                <div className="flex justify-between items-center pt-3 pr-5 pl-5 pb-3 sticky top-0" style={{ backgroundColor: "#EBF1F6" }}>

                    <div className="" style={{fontWeight: 600, fontSize: "20px"}}>All Reviews</div>
                    <div className="">
                        <span className="material-symbols-outlined cursor-pointer" style={{fontWeight:600, fontSize: "40px"}} onClick={handleOnClose} >
                            close
                        </span>
                    </div>
                </div>

                
                <div className="grid" style={{gridTemplateColumns: "1fr 1fr 1fr", gap:'10px',paddingLeft:"10px"}}>
                {
                    currentCard.map((e,i) => {
                        return (

                            <UserReviewCard UserName={e.name} rating={e.rate} input={e.input} date={e.date} key={e.name||i} />
                            )
                        })
                    }
             
                </div>
                
            </div>
        </div>
    )
}

export default AllReviewsModal
