import React, { useState } from 'react'
import UsersDemoData from '../../Demodata/UsersData'

const UpdateUserInfo = ({ setUpdateUserInfoModal,AlluserDetails,UserDetails }) => {
    
    let ArrayOfUserRoles = []
     AlluserDetails.filter(e=>ArrayOfUserRoles.push(e.UserRole));
     
    
    const [firstName,setFirstName] = useState(UserDetails.UserName)
    const [lastName,setLastName] = useState(UserDetails.LastName)

    const [email,setEmail] = useState(UserDetails.Email)

    const [mobileNumber,setMobileNumber] = useState(UserDetails.MobileNumber)
    const [userRole,setUserRole] = useState(UserDetails.UserRole)


    const handleOnCancel = () => {
        setUpdateUserInfoModal(false);
    }
   
  
    const handleOnContinue = () => {
        const userInfo = {
            "UserImage": "RandomImageUrl1",
            "UserName": firstName+lastName,
            "UserId":    "InValid",
            "CreatedOn": CreatedOn,
            "UserRole": userRole,
            "AssignedPolicy": "none",
            "ACTION": "..."
        };
        UserDetails.UserImage = "RandomImageUrl1";
        UserDetails.UserName = userInfo.UserName;
        UserDetails.CreatedOn = userInfo.CreatedOn;
        UserDetails.UserRole = userInfo.UserRole;
        UserDetails.AssignedPolicy = userInfo.AssignedPolicy;
        UserDetails.ACTION = "...";
        setUpdateUserInfoModal(false);

    }
    const today = new Date();
    const date = today.getDate();
    const month = today.getMonth() + 1; // Months are zero-indexed, so we add 1
    const year = today.getFullYear();
    const CreatedOn = `${year}-${month}-${date}`
 
   

   
    return (
        <div>
            <div className="inset-0 fixed bg-primaryGrey bg-opacity-25 flex justify-center items-center" style={{ zIndex: "100" }}>
                <div className='w-[900px] h-[730px] bg-white ' id='Modal'>
                    <div className="flex justify-between items-center pt-1 pr-5 pl-5 pb-1" style={{ backgroundColor: "#EBF1F6" }}>

                        <div className="" style={{
                            fontWeight: 600,
                            fontSize: "25px"
                        }}>Update User Info</div>
                        <div className="">
                            <span className="material-symbols-outlined cursor-pointer" style={{
                                fontWeight: 600,
                                fontSize: "40px"
                            }} onClick={handleOnCancel}>
                                close
                            </span>
                        </div>
                    </div>
                    <div className="flex  gap-[6rem] p-10">
                        <div className="left">
                            <div className=" p-5 bg-[#F6F6F6] w-[330px] h-[250px]">
                                <div className=" mb-3" style={{ fontSize: "16px", fontWeight: "600" }}>Follow these steps to add a new car</div>
                                <div className="flex ">

                                    <div className="ActiveIcon">1</div>
                                    <div className="" style={{ fontSize: "15px", color: "#0078D7", fontWeight: "600" }}>Basic User Info</div>
                                </div>
                                <div className="lineIcon"></div>
                                <div className="flex ">
                                    <div className="InActiveIcon">2</div>
                                    <div className="" style={{ fontSize: "15px", color: '#86868B' }}>Authenticate User</div>
                                </div>
                                <div className="lineIcon"></div>
                                <div className="flex">

                                    <div className="InActiveIcon">3</div>
                                    <div className="" style={{ fontSize: "15px", color: '#86868B' }}>Complete</div>
                                </div>
                            </div>
                        </div>
                        <div className="right rounded-lg">
                            <div className="">
                                <div className="" style={{ fontSize: "16px", fontWeight: "500" }}>Basic User Info</div>
                                <div className="flex w-[400px] h-[150px] justify-center items-center">

                                    <div className="w-[80px] h-[80px] bg-[#D9D9D9] flex justify-center items-center" style={{ borderRadius: "100%" }}>
                                        <div className=""></div>
                                        <img src="/person.png" alt="person" className='w-[32px] h-[31.5px]' />
                                    </div>
                                </div>
                                <div className="">
                                    <div className="First_Name  mb-3" style={{ fontWeight: "500" }}>First Name</div>
                                    <input type='text' name='First_Name' className='w-[100%] p-1 mb-3' style={{ border: "1px solid #EBF1F6", outline: "none" }} placeholder='Enter user first name' value = {firstName} onChange={(e)=>setFirstName(e.target.value)}>

                                    </input>
                                    <div className="Last_Name  mb-3" style={{ fontWeight: "500" }}>Last Name</div>
                                    <input type="text" name='Last_Name' className='w-[100%] p-1 mb-3' style={{ border: "1px solid #EBF1F6", outline: "none" }} placeholder='Enter user Last name' value = {lastName} onChange={(e)=>setLastName(e.target.value)}>

                                    </input>
                                    <div className="Email  mb-3" style={{ fontWeight: "500" }}>Email</div>
                                    <input type='email' name='Email' className='w-[100%] p-1  mb-3' style={{ border: "1px solid #EBF1F6", outline: "none" }} placeholder='Enter user Email' value = {email} onChange={(e)=>setEmail(e.target.value)}>

                                    </input>
                                    <div className="Mobile_Number  mb-3" style={{ fontWeight: "500" }}>Mobile Number</div>
                                    <input type='text' name='Mobile_Number' className='w-[100%] p-1  mb-3' style={{ border: "1px solid #EBF1F6", outline: "none" }} placeholder='Enter user Mobile Number' value = {mobileNumber} onChange={(e)=>setMobileNumber(e.target.value)}>

                                    </input>
                                    <div className="UserRole  mb-5" style={{ fontWeight: "500" }}>UserRole</div>
                                    <select name='UserRole' id='UserRole' className='w-[100%] p-1 mb-3' style={{ border: "1px solid #EBF1F6" }} onChange={(e)=>setUserRole(e.target.value)} >
                                        <option value="">Select your option</option>
                                        {ArrayOfUserRoles.map((e,i)=>{
                                            return (
                                                <option key={i} value={e} >{e}</option>

                                            )
                                        })}
                                       
                                    </select>
                                    <div className="flex justify-end items-center">
                                        <div className=" mr-5 cursor-pointer mb-4" style={{ color: "#0078D7", fontSize: "20px", fontWeight: "600" }} onClick={handleOnCancel}>
                                            Cancel
                                        </div>
                                        <div className="  px-4 py-1 flex items-center cursor-pointer mb-3" style={{
                                            width: 'max-content', backgroundColor: "#0078D7", color: 'white', fontWeight: '600', borderRadius: "20px",
                                            fontSize: "14px"
                                        }} onClick={handleOnContinue}>
                                            Save & Continue
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >

    )
}

export default UpdateUserInfo
