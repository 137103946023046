import { useLocation } from "react-router-dom"
function pathToNavigation(pathName) {
    const pathList = pathName.split('/');
    // Use filter to remove empty elements
    const filteredPathList = pathList.filter(element => element !== "");
    // changing path with underscore to redable 
    const finalArray = filteredPathList.map((e)=>{
        if(e.includes("_")){
            const words = e.split("_");
            const capitalizedString = words.map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
            return capitalizedString;
        }
        else if(e.includes("%20")){
            const words = e.split("%20");
            const capitalizedString = words.map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
            return capitalizedString;
        }else{
            return e.charAt(0).toUpperCase() + e.slice(1);
        }
    })
    // making final string 
    const resultString = finalArray.join(" > ");
    return resultString;
  }

function DashboardNavigator({pageName , custom_path}){
    const location = useLocation();
    return (
        <div className="mb-5 ml-0 my-2">
            <h1 id="d-navigator-heading">{pageName}</h1>
            {!custom_path ?
                <span id="dashboard-navigation" >{pathToNavigation(location.pathname)}</span>
                :
                <span id="dashboard-navigation">{custom_path}</span>

            }
        </div>
    )
}
export default DashboardNavigator