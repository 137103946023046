import React from "react";

import { useState, useEffect } from "react";
import TicketStatusBtn from "../components/TicketStatus/TicketStatusBtn";
import ActionButton from "../components/ActionButton";
import ReactPaginate from "react-paginate";
import TicketPriorityBtnDropDown from "../components/TicketStatus/TicketPriorityBtnDropDown";
import TicketStatusBtnDropDown from "../components/TicketStatus/TicketStatusBtnDropDown";
import { useNavigate } from "react-router-dom";
const TicketStatus = () => {
  const CardsPerPage = 5;
  const [currentPage, setCurrentPage] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [ticketStatusDemoData, setTicketStatusDemoData] = useState([]);
  const [showActionButton, setShowActionButton] = useState(false);
  const [edit,setEdit] = useState(false);
  const [selectedTicketId, setSelectedTicketId] = useState('');
  const startIndex = currentPage * CardsPerPage;
  const endIndex = startIndex + CardsPerPage;
  const handlePageClick = (selectedPage) => {
    setCurrentPage(selectedPage.selected);
  };
  const fetchData = () => {
    fetch("/JsonData/newticket.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((result) => {

        setTicketStatusDemoData(result);
        setFilteredCard(result)
      });
  }



  useEffect(() => {
    fetchData();
  }, [])
  const [filteredCard, setFilteredCard] = useState([]);

  const currentCard = filteredCard.slice(startIndex, endIndex);
  const handleOnChange = (e) => {
    setSearchText(e.target.value);
  };




  useEffect(() => {
    // Filter tickets based on the address when searchText changes
    const filtered = ticketStatusDemoData.filter((e) => {
      return (
        e.ticket_id.includes(searchText) ||
        e.requested_by.toLowerCase().includes(searchText.toLowerCase()) ||
        e.subject.toLowerCase().includes(searchText.toLowerCase())
      );
    });
    setFilteredCard(filtered);
    setCurrentPage(0); // Reset to the first page when search text changes
  }, [searchText]);

  const handleOnFilter = (appliedFilter) => {
    if (appliedFilter.target.value === "High") {
      const filteringData = ticketStatusDemoData.filter((e) => {
        return e.Priority === "High";
      });
      setFilteredCard(filteringData);
    } else if (appliedFilter.target.value === "Medium") {
      const filtered = ticketStatusDemoData.filter((e) => {
        return e.Priority === "Medium";
      });
      setFilteredCard(filtered);
    } else if (appliedFilter.target.value === "Low") {
      const filtered = ticketStatusDemoData.filter((e) => {
        return e.Priority === "Low";
      });
      setFilteredCard(filtered);
    } else if (appliedFilter.target.value === "Open") {
      const filtered = ticketStatusDemoData.filter((e) => {
        return e.Status === "Open";
      });
      setFilteredCard(filtered);
    } else if (appliedFilter.target.value === "Closed") {
      const filtered = ticketStatusDemoData.filter((e) => {
        return e.Status === "Closed";
      });
      setFilteredCard(filtered);
    } else if (appliedFilter.target.value === "Filter") {
      setFilteredCard(ticketStatusDemoData);
    }
  };
   const navigate = useNavigate()
  const handleOnDelete = (Id) => {

    const index = filteredCard.findIndex((e)=>e.ticket_id===Id)
    //  console.log(index);
  //  console.log();
    navigate('/dashboard/ticket')
    filteredCard.splice(index, 1)
}
  const handleOnEdit = (ticket_id,purpose) => {
    // console.log(ticket_id);
    setSelectedTicketId(ticket_id);
 
 
    if((edit && purpose === "Ticket") || !edit ){
          setEdit(true);
    }
    else{
      setEdit(false);
    }
  
}
  return (
    <>
      <div className="bg-[#EBF3F9] w-[100%] h-[98%] mt-[0px]">
        <div className="curr-path flex flex-col p-5 ">
          <h3 className="font-semibold text-xl my-1">Ticket Status</h3>
          <ul className="flex items-center gap-x-1">
            <li className="text-[#454F5B] text-sm font-normal">Dashboard</li>
            <img
              className="h-3"
              src="/arrow_forward_ios.png"
              alt="/arrow-forward"
            />
            <li className="text-[#212B36] font-medium">Ticket Status</li>
          </ul>
        </div>
        <div className="bg-white rounded-3xl w-[97%]  ml-5 mb-8 ">
          <div className="">
            <div
              id="search-filter"
              className="flex flex-nowrap justify-between w-[30%] pt-10 pr-10 pl-10"
            >
              <div id="search-stations">
                <span className="material-symbols-outlined">search</span>
                <input
                  type="search"
                  className="customInput"
                  value={searchText}
                  onChange={handleOnChange}
                  placeholder="Search for TicketId"
                />
              </div>

              <select
                name="total_revenue"
                id="total-revenue"
                className="bg-[#F6F6F6] rounded-md p-1 outline-none ml-[10px]"
                style={{ border: "1px solid var(--Grey-300, #DFE3E8)" }}
                onChange={handleOnFilter}
              >
                <option value="Filter">Filter</option>
                <option value="High">High</option>
                <option value="Medium">Medium</option>
                <option value="Low">Low</option>
                <option value="Open">Open</option>
                <option value="Closed">Closed</option>
              </select>
            </div>
          </div>
          <div className="">
            <div className=" pt-1 pr-8 pl-8">
              <table className="w-[100%]">
                <thead>
                  <tr>
                    <th
                      className="heading_row_ticket"
                      style={{
                        paddingLeft: "15px",
                        paddingTop: "15px",
                        paddingTop: "15px",
                      }}
                    >
                      {" "}
                      Ticket Id
                    </th>
                    <th
                      className="heading_row_ticket"
                      style={{
                        paddingLeft: "15px",
                        paddingTop: "15px",
                        paddingTop: "15px",
                      }}
                    >
                      {" "}
                      Requested by
                    </th>
                    <th
                      className="heading_row_ticket"
                      style={{
                        paddingLeft: "15px",
                        paddingTop: "15px",
                        paddingTop: "15px",
                      }}
                    >
                      {" "}
                      Subject
                    </th>
                    <th
                      className="heading_row_ticket"
                      style={{
                        paddingLeft: "15px",
                        paddingTop: "15px",
                        paddingTop: "15px",
                      }}
                    >
                      {" "}
                      Assigned
                    </th>
                    <th
                      className="heading_row_ticket"
                      style={{
                        paddingLeft: "15px",
                        paddingTop: "15px",
                        paddingTop: "15px",
                      }}
                    >
                      {" "}
                      Priority
                    </th>
                    <th
                      className="heading_row_ticket"
                      style={{
                        paddingLeft: "15px",
                        paddingTop: "15px",
                        paddingTop: "15px",
                      }}
                    >
                      {" "}
                      Status
                    </th>
                    <th
                      className="heading_row_ticket"
                      style={{
                        paddingLeft: "15px",
                        paddingTop: "15px",
                        paddingTop: "15px",
                      }}
                    >
                      {" "}
                      Created Date
                    </th>
                    <th
                      className="heading_row_ticket"
                      style={{
                        paddingLeft: "15px",
                        paddingTop: "15px",
                        paddingTop: "15px",
                      }}
                    >
                      {" "}
                      Due Date
                    </th>
                    <th
                      className="heading_row_ticket"
                      style={{
                        paddingLeft: "15px",
                        paddingTop: "15px",
                        paddingTop: "15px",
                      }}
                    >
                      {" "}
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {currentCard.map((e) => {
                    return (
                      <tr key={e.ticket_id}>
                        <td className="item3">{e.ticket_id}</td>
                        <td className="item3">{e.requested_by}</td>
                        <td className="item3">{e.subject}</td>
                        <td className="item3">{e.assigned_to}</td>
                        <td className="item3">                        {(edit && e.ticket_id===selectedTicketId)?<TicketPriorityBtnDropDown
                          color={
                            e.Priority === "High"
                              ? "#FF0000"
                              : e.Priority === "Medium"
                                ? "#919333"
                                : "#0085FF"
                          }
                          backgroundColor={
                            e.Priority === "High"
                              ? "#ffcdcd"
                              : e.Priority === "Medium"
                                ? "rgb(255 249 194)"
                                : "rgb(221 234 255)"
                          }
                          status={e.Priority}
                          borderColor={
                            e.Priority === "High"
                              ? "#FF0000"
                              : e.Priority === "Medium"
                                ? "#FFE602"
                                : "#0276FF"
                          }
                          TicketDetails={e}
                        />:<TicketStatusBtn
                        color={
                          e.Priority === "High"
                            ? "#FF0000"
                            : e.Priority === "Medium"
                              ? "#919333"
                              : "#0085FF"
                        }
                        backgroundColor={
                          e.Priority === "High"
                            ? "#ffcdcd"
                            : e.Priority === "Medium"
                              ? "rgb(255 249 194)"
                              : "rgb(221 234 255)"
                        }
                        status={e.Priority}
                        borderColor={
                          e.Priority === "High"
                            ? "#FF0000"
                            : e.Priority === "Medium"
                              ? "#FFE602"
                              : "#0276FF"
                        }
                      />}</td>
                        <td className="item3"> {(edit && e.ticket_id===selectedTicketId)?<TicketStatusBtnDropDown
                          color={e.Status === "Open" ? "#05FF00" : "#4A4A4A"}
                          backgroundColor={
                            e.Status === "Open" ? "rgb(222 255 220)" : "#E8EEF3"
                          }
                          status={e.Status}
                          borderColor={
                            e.Status === "Open" ? "#35FF02" : "#CBD4C8"
                          }
                          TicketDetails = {e}
                        />:<TicketStatusBtn
                        color={e.Status === "Open" ? "#05FF00" : "#4A4A4A"}
                        backgroundColor={
                          e.Status === "Open" ? "rgb(222 255 220)" : "#E8EEF3"
                        }
                        status={e.Status}
                        borderColor={
                          e.Status === "Open" ? "#35FF02" : "#CBD4C8"
                        }
                      />}</td>
                        <td className="item3 ">
                          {e.CreatedDate}
                        </td>
                        <td className="item3">{e.DueDate}</td>
                        <td className="item3 ">

                          <ActionButton purpose={(edit && e.ticket_id===selectedTicketId)?"Apply":"Ticket"}
                          handleOnEdit={()=>handleOnEdit(e.ticket_id,(edit && e.ticket_id===selectedTicketId)?"Apply":"Ticket")} Details={e} 
                          handleOnDelete={()=>handleOnDelete(e.ticket_id)}/>


                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>

              <div className="flex w-[100%] justify-center mt-10 pb-10">
                {" "}
                <ReactPaginate
                  breakLabel="..."
                  nextLabel=">"
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={5}
                  pageCount={Math.ceil(ticketStatusDemoData.length / CardsPerPage)}
                  previousLabel="<"
                  containerClassName="pagination flex justify-center gap-4"
                  pageClassName="w-6 h-6 flex justify-center items-center"
                  pageLinkClassName="text-sm font-semibold "
                  activeClassName="bg-[#0078D7] rounded text-[#FFFFFF]"
                  previousLinkClassName="text-[#0078D7] font-bold"
                  nextLinkClassName="text-[#0078D7] font-bold"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
   
    </>
  );
};

export default TicketStatus;
