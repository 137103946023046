import React from 'react';
import { Line } from 'react-chartjs-2';
import { Filler } from 'chart.js';

import {Chart as ChartJS,CategoryScale,PointElement,Tooltip,LinearScale} from 'chart.js'
ChartJS.register(Filler,CategoryScale,PointElement,Tooltip,LinearScale)






const AreaChart = ({aspectRatio,Filler,propsData,backgroundColor}) => {
  const data = {
    labels: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Faturday', 'Sunday'],
    datasets: [
      {
        label: 'My First dataset',
        fill: Filler,
        
        backgroundColor: backgroundColor,
        borderColor: 'rgba(75,192,192,1)',
        tension: 0.6,
        borderDash: [],
        
        
        
        data: propsData
      }
    ]
  };
  const option = {
    ticks:{
      color:"green",
    },
    maintainAspectRatio:true,
    aspectRatio:aspectRatio,
    // responsive: true,
    plugins: {
        legend:{
            display:false
          }
    },
    scales: {
        x: {
          grid: {
            display: false,
          },
          ticks:{
            font:{
              size:'16px',
 

            },
          },
        },
  
        y: {
            grid: {
                display: false,
              },
              ticks:{
                font:{
                  size:'16px',

                },
              },
        }
      },
  };
   return(

     <div>
   
    <Line data={data} options={option} />
  </div>
    )
}

export default AreaChart;