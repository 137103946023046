import React from 'react'
import { useState,useRef } from 'react'


const ActionButton = ({purpose,handleOnEdit,Details,handleOnDelete}) => {
     
  
  const [open, setOpen]= useState(false)
  const menuRef = useRef()
  const clickRef = useRef()
  window.addEventListener('click', (e)=>{
    if(e.target !== menuRef.current && e.target !== clickRef.current){
      setOpen(false)
    }
  })

  return (
    <div>

     
<div className='relative inline-block text-left'>
      <img ref={clickRef} onClick={()=>{setOpen(!open)}} className='px-3 cursor-pointer ' src="/more_horiz.png" alt="actionBtn" />
      {open && (
        <div className='customdropdown p-[10px] absolute right-0 z-10 mt-2 w-[8rem] origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1"' ref={menuRef}>
           <div className="flex gap-3 mb-2 cursor-pointer" style={{fontSize:"14px"}} onClick= {()=>handleOnEdit(Details)}>
              <span ><img src="/edit.png" alt="" /></span> Edit {purpose}
          </div>
          <div className="flex gap-3 cursor-pointer" onClick={()=>handleOnDelete()} style={{fontSize:"14px"}}>
          <span><img src="/delete.png" alt="" /></span>Delete 
          </div>

         </div>
         
         )}
    </div>
    </div>
  );
}

  export default ActionButton
