import React, { useState } from 'react'
import UsersDemoData from '../../Demodata/UsersData';
import Loader from "../loadingSpinner"
const AddUserPage2 = ({setAddUserPage2,setAddUserPage3,setAddedUserId}) => {
    const[userId,setUserId] = useState("");
    const[password,setPassword] = useState("");
    const[confirmPassword,setConfirmPassword] = useState("");
    let [loading, setLoading] = useState(false);
    const[loginInfo,setLoginInfo] = useState({
        "userId":"",
        "password":""
    })
    const handleOnCancel = ()=>{
        setAddUserPage2(false);        
    }
    const handleOnContinue = ()=>{
        setLoading(true);
        setTimeout(() => {
           setLoading(false);
           if(password===confirmPassword){
            setLoginInfo({
                "userId":userId,
                "password":password
            })
            setAddedUserId(userId);
            UsersDemoData.forEach(user => {
                for (const key in user) {
                    if(user[key]==="InValid"){
                        user[key] = userId;
                        
                    }
                }
            });
            // console.log(UsersDemoData);
            setAddUserPage2(false);
            setAddUserPage3(true);
        }
        else{
            alert("Unmatched Password");
        }
        }, 2300);
       
       
    }
    return (
        <div>
            <Loader loading={loading}/>
            <div className="inset-0 fixed bg-primaryGrey bg-opacity-25 flex justify-center items-center" style={{zIndex:"100"}}>
                <div className='w-[900px] h-[600px]  bg-white rounded-xl overflow-hidden' id='Modal'>
                    <div className="flex justify-between items-center pt-1 pr-5 pl-5 pb-1" style={{ backgroundColor: "#EBF1F6" }}>

                        <div className="" style={{
                            fontWeight: 600,
                            fontSize: "20px"
                        }}>Add New User</div>
                        <div className="">
                            <span className="material-symbols-outlined cursor-pointer" style={{
                                fontWeight: 600,
                                fontSize: "40px"
                            }} onClick={handleOnCancel} >
                                close
                            </span>
                        </div>
                    </div>
                    <div className="flex  gap-[6rem] p-10">
                        <div className="left">
                            <div className=" p-5 bg-[#F6F6F6] w-[450px] h-[250px]">
                                <div className=" mb-3" style={{ fontSize: "16px", fontWeight: "600" }}>Follow these steps to add a new car</div>
                                <div className="flex ">

                                    <div className="InActiveIcon">1</div>
                                    <div className="" style={{  fontSize:"15px",color: "#86868B",  }}>Basic User Info</div>
                                </div>
                                <div className="lineIcon"></div>
                                <div className="flex ">
                                    <div className="ActiveIcon">2</div>
                                    <div className="" style={{ fontSize:"15px", fontWeight: "600",color: '#0078D7' }}>Authenticate User</div>
                                </div>
                                <div className="lineIcon"></div>
                                <div className="flex">

                                    <div className="InActiveIcon">3</div>
                                    <div className="" style={{ fontSize:"15px", color: '#86868B' }}>Complete</div>
                                </div>
                            </div>
                        </div>
                        <div className="right rounded-lg w-[100%]">
                            <div className="" style={{
                                fontWeight: "600",
                                fontSize: "17px",
                                marginBottom: "10px"
                            }}>
                                Authenticate User
                            </div>
                            <div className="flex  mb-3 items-center"><img src="/info.png" alt="" style={{  width: "20px", height: "20px", marginRight: "5px", fontSize:"16px" }} />Set User Id & password for login.</div>
                            <div className="*Create User Id  mb-3" style={{ fontWeight: "500",fontSize:"16px" }}>Create User Id</div>
                            <input type="text" name='*Create User Id' className='w-[100%] p-1 text-lg mb-3' style={{ border: "1px solid #EBF1F6", outline: "none",fontSize:"16px" }} placeholder='Enter  User Id'value={userId} onChange={(e)=>setUserId(e.target.value)} >

                            </input>
                            <div className="Create new password mb-3" style={{ fontWeight: "500",fontSize:"16px" }}>Create new password</div>
                            <input type='password' name='Create new password' className='w-[100%] p-1  mb-3' style={{ border: "1px solid #EBF1F6", outline: "none", fontSize:"16px" }} placeholder='Enter new password' value={password} onChange={(e)=>setPassword(e.target.value)} >

                            </input>
                            <div className="Re-enter new password  mb-3" style={{ fontWeight: "500",fontSize:"16px" }}>Re-enter new password</div>
                            <input type='password' name='*Re-enter new password' className='w-[100%] p-1  mb-3' style={{ border: "1px solid #EBF1F6", outline: "none" ,fontSize:"16px" }} placeholder='enter Re-enter new password' value={confirmPassword} onChange={(e)=>setConfirmPassword(e.target.value)} >

                            </input>
                            <div className="flex justify-end items-center">
                                <div className=" mr-5 cursor-pointer mb-4" style={{ color: "#0078D7", fontSize: "20px", fontWeight: "600" }} onClick={handleOnCancel}>
                                    Cancel
                                </div>
                                <div className="  px-4 py-1 flex items-center cursor-pointer mb-3" style={{
                                    width: 'max-content', backgroundColor: "#0078D7", color: 'white', fontWeight: '600', borderRadius: "20px",
                                    fontSize: "14px"
                                }} onClick={handleOnContinue} >
                                    Save & Continue
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    )
}

export default AddUserPage2
