import React from 'react'
import { Bar } from 'react-chartjs-2'
import { useState } from 'react';

const EnergyConsumptionCard = ({ aspectRatio, propsData }) => {
    // console.log(propsData);
    let newData = {};
    let cnt = 0;
    for (let key in propsData[0]) {
        if (cnt < 6) {
            newData[key] = propsData.map(e => e[key]);
            cnt++;
        } else {
            break;
        }
        // console.log(newData);
    }
    const [data, setData] = useState(newData);
    const [duration, setDuration] = useState("Last 6 months")

    const handleOnChange = (e) => {
        setDuration(e.target.value)
        if (e.target.value === "Last 4 months") {
            //  console.log("in");
            let newData = {};
            let cnt = 0;
            for (let key in propsData[0]) {
                if (cnt < 4) {
                    newData[key] = propsData.map(e => e[key]);
                    cnt++;
                } else {
                    break;
                }
                // console.log(newData);
            }
            setData(newData);
            
            
            
        }
        
        else if (e.target.value === "Last 6 months") {
            let newData = {};
            let cnt = 0;
            for (let key in propsData[0]) {
                if (cnt < 6) {
                    newData[key] = propsData.map(e => e[key]);
                    cnt++;
                } else {
                    break;
                }
                // console.log(newData);
            }
            setData(newData);
        }
        else if (e.target.value === "Last 12 months") {
            let newData = {};
            let cnt = 0;
            for (let key in propsData[0]) {
                if (cnt < 12) {
                    newData[key] = propsData.map(e => e[key]);
                    cnt++;
                } else {
                    break;
                }
                // console.log(newData);
            }
            setData(newData);
        }
        
    }
    
    // console.log(data);
    let labels = [];
    let ExtractedData = [];
    for(let keys in data){
        labels.push(keys)
        ExtractedData.push(data[keys])
    }
    let NumericData = [];
  
    (ExtractedData.forEach((e)=>{
        (e.map(e=>(NumericData.push(e.split(' ')[0]))))    
    }));
    // console.log(NumericData);
    const option = {
        maintainAspectRatio: true,
        aspectRatio,
        responsive: true,
        plugins: {
            legend: {
                display: false
            }
        },
        scales: {
            x: {
                grid: {
                    display: false,
                },
                ticks: {
                    font: {
                        size: '16px',
                    },
                },
            },
            y: {
                grid: {
                    display: false,
                },
                ticks: {
                    font: {
                        size: '16px',
                    },
                },
            }
        },
    };
    const maindata = {
        labels: labels,
        datasets: [
            {
                label: "",
                data: NumericData,
                backgroundColor: "rgba(0, 120, 215, 1)",
                borderRadius: 0,
                barThickness: 30
            }
        ],


    };

    return (
        <div>
            <div className="bg-white rounded-lg h-[450px] w-[100%] p-5">
                <div className="flex justify-between ">
                    <h2 className=' font-Roboto text-2xl font-bold'>{`Energy consumed in ${duration}`}</h2>
                    <div className="options" >
                        <select name="total_revenue" id="total-revenue" className='bg-[#DFE3E8] rounded-md p-1 outline-none ml-3' onChange={handleOnChange}>
                            
                            <option value="Last 6 months">Duration</option>
                            <option value="Last 4 months">Last 4 months</option>
                            <option value="Last 6 months">Last 6 months</option>
                            <option value="Last 12 months">Last 12 months</option>

                        </select>
                        <div className='text-[0.7rem] mt-2 text-textGray'>
                            From Sept 2023 to Jan 2024
                        </div>
                    </div>
                </div>
                <div className="barchart">
                    <Bar data={maindata} options={option} />
                </div>
            </div>
        </div>
    )
}

export default EnergyConsumptionCard
