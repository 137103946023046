import React from 'react'
import AreaChart from '../AdminDashboardComponents/AreaChart'

const Charging_performance_modal = ({showModal,setShowModal,propsData,ChargerHealth,ChargerState,ChargerSerialNumber}) => {
    const handleOnClick = (e)=>{
  
            setShowModal(false);


    }
    return (
        <div className="">

        
        { showModal && 
        <div className="inset-0 fixed bg-primaryGrey bg-opacity-25 flex justify-center items-center" style={{zIndex:"100"}}  >

            <div className='w-[800px] h-[600px] rounded-xl overflow-hidden bg-white' id='Modal'>
                <div className="Top ">
                    <div className="flex justify-between items-center py-3 pl-5 pr-5 " style={{ backgroundColor: "#EBF1F6" }}>

                        <div className="" style={{
                            fontWeight: 600,
                            fontSize: "23px"
                        }}>Serial No.: {ChargerSerialNumber}</div>
                        <div className="">
                            <span className="material-symbols-outlined cursor-pointer" style={{
                                fontWeight: 600,
                                fontSize: "40px"
                            }} onClick={handleOnClick}>
                                close
                            </span>
                        </div>
                    </div>
                    <div className="flex">
                        <div className=" flex flex-col p-5">
                            <span style={{
                                fontWeight: "600", fontSize: "16px", color: "#637381",
                            }}>Charger State</span>
                            <span className='text-[.7rem] font-medium px-2 py-1 text-center rounded-lg text-textGray' style={ChargerState === "charging" ? { fontSize: "15px", border: "2px solid #00D77D ", weight: '600', color: "#00D77D", marginTop: '10px' } : ChargerState === "damaged" ? { fontSize: "15px", border: "2px solid #EF9E00 ", weight: '600', color: "#EF9E00", marginTop: '10px' } : { fontSize: "15px", border: "2px solid #F4CF0E", weight: '600', color: "#F4CF0E", marginTop: '10px' }}>{ChargerState}</span>
                        </div>
                        <div className=" flex flex-col p-5">
                            <span style={{
                                fontWeight: "600", fontSize: "16px", color: "#637381",
                            }}>Charger Health</span>
                            <span className='text-[.7rem] font-medium px-2 text-center rounded-lg text-primaryBlue py-1' style={ChargerHealth === "healthy" ? { fontSize: "15px", border: "2px solid #0078D7", weight: '600', color: "#0078D7", marginTop: '10px' } : ChargerState === "damaged" ? { fontSize: "15px", border: "2px solid #E40000 ", weight: '600', color: "#E40000", marginTop: '10px' } : { fontSize: "15px", border: "2px solid #E40000", weight: '600', color: "#E40000", marginTop: '10px' }}>{ChargerHealth}</span>
                        </div>
                    </div>
                </div>
                <div className="middle">
                    <div className="  pr-5 pl-5" style={{ fontWeight: "550", fontSize: "22px" }}>Charging performance</div>
                    <div className="">
                        <div className="flex justify-end">
                            <div className=" flex flex-col pr-5 pl-5 pt-2">
                                <span style={{
                                    fontWeight: "600", fontSize: "20px", color: "#637381",
                                }}>Charger time</span>
                                <span style={{
                                    fontWeight: "600", fontSize: "20px", color: "#000", paddingLeft: "5px", paddingRight: "10px",
                                }}>22 Hours</span>
                            </div>
                            <div className=" flex flex-col  pr-5 pl-5 pt-2">
                                <span style={{
                                    fontWeight: "600", fontSize: "20px", color: "#637381",
                                }}>Voltage drawn</span>
                                <span style={{
                                    fontWeight: "600", fontSize: "20px", color: "#000", paddingLeft: "5px", paddingRight: "10px",
                                }}>10 Volts</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bottom p-5">
                    <AreaChart aspectRatio={"3.5"} Filler={false} propsData={propsData}/>
                    <div className="flex w-[100%] justify-center">
                        <div className="" style={{ fontSize: "25px", fontWeight: "600", marginTop: "40px" }}>Current drawn while charging</div>
                    </div>
                </div>
            </div>
        </div>
                            }
        </div>
    )
}

export default Charging_performance_modal
