function Card({
  title,
  handleOnDelete,
  eleId,
  details,
  stationName,
  stationAddress,
  setShowReport,
  setReportDetails,
}) {
  const reportDetails = {
    SerialNumber: details._id,
    StationName: stationName,
    StationAddress: stationAddress,
    Report: details.report?.map((e) => {
      return {"title":e.title,"report":e.report};
    }),
    GeneratedOn: "Generated at 17:02 on March 1, 2023",
  };
  
  const handleOnViewReport = () => {
    setReportDetails(reportDetails);
    setShowReport(true);
  };
  return (
    <div className="card-1 border border-[#CBD4C8] rounded-md p-4">
      <div className="flex justify-between items-center">
        <h4 className="text-xl font-semibold"> {details.report?.map((e) => {
            return e.title;
          })}</h4>
        <div className="flex items-center gap-3">
          <img
            src="/delete.png"
            alt="delete-icon"
            style={{ cursor: "pointer" }}
            onClick={() => handleOnDelete(eleId)}
          />
        </div>
      </div>
      <div className="text-sm text-[#212B36]">
        Generated for Charger of Serial No.:{" "}
        <span className="font-semibold"> {details._id}</span>
      </div>
      <div className="text-sm text-[#212B36]">
        Charging Station Name:{" "}
        <span className="font-semibold">{stationName}</span>
      </div>
      <div className="text-sm text-[#212B36]">
        Charging Station Address:{" "}
        <span className="font-semibold"> {stationAddress}</span>
      </div>
  

      <div className="flex justify-between items-center mt-4">
        <div className="text-sm text-[#212B36] font-semibold">
          Updated:{details.lastUpdated?details.lastUpdated:details.charger.lastUpdated}
        </div>
        <div
          className="bg-primaryBlue cursor-pointer text-white px-3 py-1 rounded-lg"
          onClick={handleOnViewReport}
        >
          View Report
        </div>
      </div>
    </div>
  );
}
export default Card;
