import React from 'react'
import { Doughnut } from "react-chartjs-2";
import { Link } from 'react-router-dom';
const DoughNutChart = ({unavailableStations,availableStations}) => {
  let totalStations = (unavailableStations + availableStations).toString()
// console.log((totalStations));

const data = {
    labels: ['Red', 'Green'],
    datasets: [
      {
        data: [unavailableStations, availableStations],
        backgroundColor: [
          '#F84F56',
          '#00D77D',
        ],
        cutout: 85
      },
    ],
  };
  const options = {
    plugins: {
      legend: {
        display: false
      }
    },
    maintainAspectRatio: false,
    // aspectRatio:1
  }
  const textcenter = {
    id: "textcenter",
    beforeDatasetsDraw(chart,args,plusginOptions){
      const{ctx,data} = chart;
      ctx.save();
      ctx.font = 'bolder 40px Roboto';
      ctx.fillStyle = 'black';
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      ctx.fillText("10",chart.getDatasetMeta(0).data[0].x,chart.getDatasetMeta(0).data[0].y)

    }
  }
    return (
      <div className="Charging-station-status-container rounded-xl p-5  mx-auto   bg-white w-[100%] h-[24rem]">
        <div className="flex justify-between ">
          <h4 className="text-xl font-semibold"> Charging Stations Status</h4>
          <Link to={"/dashboard/charging_stations"}>
          <div className="bg-primaryBlue text-white px-3 py-1 rounded-lg">
            View Stations
          </div>
          </Link>
        </div>
        <div className="flex justify-between  p-3">
          <div className="flex items-center  h-[160px] w-[180px] justify-center  ">
            <div className=" Doughnut-container flex justify-center content-center  ">
  
              <div className=" w-[17rem] h-[24rem] p-2 ml-7  mt-14">
                <Doughnut plugins={[textcenter]} data={data} options={options}  style={{width:"250px",height:"250px"}}/>
              </div>
            </div>
          </div>
          <div className="flex flex-col items-center  w-[50%] justify-between  ">
  
            <div className=" flex flex-col justify-center items-center mt-3">
              <span className="font-semibold text-lg ">Total Charging Stations</span>
              <span className="font-bold text-3xl">{availableStations+unavailableStations}</span>
            </div>
            <div className="flex flex-col  mt-16 items-start">
              <span className="font-semibold text-lg flex  justify-center"><div className="dough-label" style={{ backgroundColor: '#00D77D' }}></div>Available <span>({availableStations})</span></span>
              <span className="font-semibold text-lg flex justify-center "><div className="dough-label" style={{ backgroundColor: '#F84F56' }}></div>Unavailable <span>({unavailableStations})</span></span>
            </div>
          </div>
        </div>
      </div>
    )
}

export default DoughNutChart
