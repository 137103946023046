import React from 'react'

const TicketStatusBtn = ({ backgroundColor, color, status, borderColor }) => {
    return (
        <div>
            <div className="" style={{
                border: `1px solid ${borderColor}`,
                borderRadius: "9px",
                width:"100px",
                padding:'4px',
                fontWeight: "500",
                textAlign:"center",
                fontSize: "12px",
                backgroundColor: backgroundColor,
                color: color,
            }}>
                {status}
            </div>
        </div>
    )
}

export default TicketStatusBtn
