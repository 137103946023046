import {BrowserRouter ,Routes,Route } from "react-router-dom"
import HomePage from "./pages/home"
import Dashboard from "./pages/dashboard"
import ChargingStations from "./pages/chargingStations"
import Station from "./pages/station"
import {Children} from "react"
import NavBar from "./components/Navbar"
import Profile from "./pages/Profile"
import Header from "./components/Header"

// custom style sheet 
import "./styles/style.css"
import Accsess from "./pages/Accsess"
import Transaction from "./pages/Transaction"
import TicketStatus from "./pages/TicketStatus"
import Help from "./pages/Help"

function TemplateNav({children}){
  // const childElements = React.Children.toArray(props.children);
  return (
    <>
    <Header/>
    <div className="flex flex-row responsive">
      <NavBar/>
      {Children.toArray(children)}
    </div>
    </>
  )
}
function App() {
  return (
    <BrowserRouter>
            
        <Routes>
          <Route path="/" element={<HomePage/>}></Route>
          <Route path="/dashboard" element={<TemplateNav><Dashboard/></TemplateNav>}></Route>
          <Route path="/dashboard/charging_stations" element={<TemplateNav><ChargingStations/></TemplateNav>}></Route>
          <Route path="/dashboard/charging_stations/:station" element={<TemplateNav><Station/></TemplateNav>}></Route>
          <Route path="/dashboard/access" element = {<TemplateNav><Accsess/></TemplateNav>}/>
          <Route path="/dashboard/transactions" element = {<TemplateNav><Transaction/></TemplateNav>}/>
          <Route path="/dashboard/ticket" element = {<TemplateNav><TicketStatus/></TemplateNav>}/>
          <Route path="/dashboard/Help" element = {<TemplateNav><Help/></TemplateNav>}/>
          <Route path="/profile" element = {<TemplateNav><Profile/></TemplateNav>}/>
        </Routes>
    </BrowserRouter>
  );
}

export default App;
