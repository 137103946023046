import React, { useEffect, useState } from 'react'
import DashboardNavigator from "../DashboardNavigator";
import BarCharts from "../BarCharts";

const   TotalSessionCard = ({CardName,barcolor,propsData,aspectRatio,height}) => {
    const [data,setData] = useState(propsData[0]);
    


    // useEffect((e)=>{
    //     setData(propsData[0])
    // },[data])
    const handleOnChange = (e) =>{
        if(e.target.value === "This months"){
            setData(propsData[0])
        }
        else if (e.target.value === "Last month"){
            setData(propsData[1]);
        }
        else if (e.target.value === "Last 2 month"){
            setData(propsData[2]);
        }
        else if (e.target.value === "Last 6 month"){
            setData(propsData[3]);
        }
        else if (e.target.value === "Last 12 month"){
            setData(propsData[4]);
        }
        
    }
    // console.log(data);
    return (
    
    <div className="dashboard rounded-lg" style={{height:height}} >
            
            <div className=' rounded-lg  cursor-pointer'>
                <div className="heading flex justify-between ">
                    <h1 className='font-semibold' style={{fontSize:"20px"}}>{CardName}</h1>
                    <div className="options ">
                        <select name="total_revenue" id="total-revenue" className='bg-[#DFE3E8] rounded-md p-1 outline-none ml-3' onChange={handleOnChange}>
                            <option value="This months">This months</option>
                            <option value="Last month">Last month</option>
                            <option value="Last 2 month">Last 2 month</option>
                            <option value="Last 6 month">Last 6 month</option>
                            <option value="Last 12 month">Last 12 month</option>
                        </select>
                        <div className='text-[0.7rem] mt-2 text-textGray'>
                            From Sept 2023 to Jan 2024
                        </div>
                    </div>
                </div>
               
                <BarCharts barcolor ={barcolor} propsData = {data} aspectRatio={aspectRatio} height={height}/>
            </div>
        </div>
  )
}

export default TotalSessionCard
