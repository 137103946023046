const TotalRevenueCardDemoData = [
    {
      "totalRevenueGenerated": 3000000,
      "totalTransactionsCompleted": 2000,
      "point": [6500, 5900, 8000, 8100, 5600, 5500, 4000]
    },
    {
      "totalRevenueGenerated": 2500000,
      "totalTransactionsCompleted": 1800,
      "point": [6000, 5001, 4000, 8100, 5600, 2300, 3200]
    },
    {
      "totalRevenueGenerated": 4500000,
      "totalTransactionsCompleted": 3000,
      "point": [3300, 7200, 4500, 1900, 8800, 9400, 6000]
    },
    {
      "totalRevenueGenerated": 15000000,
      "totalTransactionsCompleted": 1200,
      "point": [1200, 2800, 9000, 7300, 5000, 3800, 4200]
    },
    {
      "totalRevenueGenerated": 35000000,
      "totalTransactionsCompleted": 2200,
      "point": [1700, 6600, 2300, 4900, 8700, 6200, 1500]
    }
  ]
  ;
export default TotalRevenueCardDemoData