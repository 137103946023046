const TotalSessionCardDemoData = [
    {
      "Mon": 50,
      "Tue": 100,
      "Wed": 150,
      "Thu": 200,
      "Fri": 250,
      "Sat": 300,
      "Sun": 350
    },
    {
      "Mon": 100,
      "Tue": 150,
      "Wed": 200,
      "Thu": 250,
      "Fri": 300,
      "Sat": 350,
      "Sun": 400
    },
    {
      "Mon": 150,
      "Tue": 200,
      "Wed": 250,
      "Thu": 300,
      "Fri": 350,
      "Sat": 400,
      "Sun": 450
    },
    {
      "Mon": 200,
      "Tue": 250,
      "Wed": 300,
      "Thu": 350,
      "Fri": 400,
      "Sat": 450,
      "Sun": 500
    },
    {
      "Mon": 250,
      "Tue": 300,
      "Wed": 350,
      "Thu": 400,
      "Fri": 450,
      "Sat": 500,
      "Sun": 550
    }
  ]
  


export default TotalSessionCardDemoData